





































































































import Vue from "vue";

import Component from "vue-class-component";
import Organisation from "@/typing/webbeat/organisation.class";
import ToastrHelper from "@/helpers/toastHelper";
import { GetTeamMemberUpdateData, PutTeamMemberUpdate, DeleteTeamMember } from "@/api/apiWebbeat";
import Product from "@/typing/webbeat/product.class";
import Picker from "@/components/Picker/Picker.vue";
import PickerMultiple from "@/components/Picker/PickerMultiple.vue";
import Loader from "@/components/Loader.vue";
import TeamMemberCreateResponseModel from "@/typing/webbeat/teamMemberCreateResponseModel.class";
import TeamMember from "@/typing/webbeat/teamMember.class";
import Modal from "@/components/Modal.vue";

@Component({
  components: {
    Picker,
    PickerMultiple,
    Loader,
    Modal,
  },
})
export default class EditTeamMember extends Vue {
  organisations: Organisation[] = [];
  products: Product[] = [];
  selectedOrganisation = null;
  selectedProduct = null;
  selectedTeamMember = null;
  model = new TeamMemberCreateResponseModel();
  isLoading = false;
  showEdit = false;
  showDelete = false;
  teamMember: TeamMember | undefined;
  teamMembers: TeamMember[] = [];

  async mounted(): Promise<void> {
    this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      this.selectedOrganisation = null;
      this.selectedProduct = null;
      this.selectedTeamMember = null;
      const result = await GetTeamMemberUpdateData();
      this.model = result.data.body;
      this.organisations = result.data.body.organisations;
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  async postEditTeamMember(): Promise<void> {
    const model = { ...this.teamMember } as any;
    if (!model) return;

    try {
      const result = await PutTeamMemberUpdate(model.id, model.role, model.description, model.isPublicProfile);
      if (result.data.hasErrors) {
        ToastrHelper.successToastr(result.data.errors[0].message, this);
      } else {
        this.showEdit = false;
        this.loadData();
        ToastrHelper.successToastr("Team member is updated", this);
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  async deleteTeamMember(): Promise<void> {
    const model = { ...this.teamMember } as any;
    if (!model) return;

    try {
      const result = await DeleteTeamMember(model.id);
      if (result.data.hasErrors) {
        ToastrHelper.successToastr(result.data.errors[0].message, this);
      } else {
        this.showDelete = false;
        this.loadData();
        ToastrHelper.successToastr("Team member is removed from the team", this);
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  getProductList(organisationId: number): Product[] {
    const model = this.model.products.filter((x) => x.organisationId == organisationId);

    return model;
  }
  getTeamMember() {
    const model = this.teamMembers.find((x) => x.userId === this.selectedTeamMember);
    if (!model) return;

    this.teamMember = model;
  }

  getTeamMembers(productId: number): TeamMember[] {
    const model = this.model.products.find((x) => x.id == productId);

    if (!model) return [];
    this.teamMembers = [...model.teamMembers];
    return model.teamMembers;
  }
}
