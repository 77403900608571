import QueueHistory from "../queueHistory.class";
import BoundsGraphDataset from "./graphBase.class";

export default class QueueGraph extends BoundsGraphDataset {
  queueName: string;
  history: QueueHistory;

  constructor(t?: QueueGraph) {
    super(t);
    this.queueName = t?.queueName ?? "";
    this.history = t?.history ?? new QueueHistory();
  }
}
