import { dateTimeFormat, dayjsEx } from "@/utils/dayjs";
import BuildPackage from "./buildPackage.class";
import BaseProp from "./baseProp.class";

export default class Build extends BaseProp {
  branch: string;
  buildNumber: string;
  isLatestBuild: boolean;
  riskScore: number;
  name: string;
  packages: BuildPackage[];
  timestamp: string;
  timestampFormat: string;

  constructor(p?: Build) {
    super(p);

    this.branch = p?.branch ?? "";
    this.buildNumber = p?.buildNumber ?? "";
    this.isLatestBuild = p?.isLatestBuild ?? false;
    this.name = p?.name ?? "";
    this.riskScore = p?.riskScore ?? 0;
    this.timestamp = p?.timestamp ?? "";
    this.timestampFormat = this.timestamp ? dayjsEx.utc(this.timestamp).format(dateTimeFormat) : "";
    this.isDeprecated = p?.isDeprecated ?? false;
    this.isVulnerable = p?.isVulnerable ?? false;

    if (p?.packages && p?.packages.length) {
      this.packages = p.packages.map((x) => new BuildPackage(x));
    } else {
      this.packages = [];
    }
  }
}
