export default class PackageRequest {
  packageName: string;
  packageVersion: string;
  reason: string;

  constructor(p?: Partial<PackageRequest>) {
    this.packageName = p?.packageName ?? "";
    this.packageVersion = p?.packageVersion ?? "";
    this.reason = p?.reason ?? "";
  }
}
