





























































































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import ToastrHelper from "@/helpers/toastHelper";
import InfraComponent from "@/typing/webbeat/component.class";
import Loader from "@/components/Loader.vue";
import Server from "@/typing/webbeat/server.class";
import storeTypes from "@/store/storeTypes";
import Trend from "@/typing/webbeat/trend.class";
import Monitor from "@/components/Monitor.vue";
import ServerComponent from "@/components/ServerComponent.vue";
import Subscription from "@/typing/webbeat/subscription.class";
import ChartDataModel from "@/typing/webbeat/chartDataModel.class";
import {
  GetTrendHistory,
  UpdateComponentDescription,
  GetSubscriptionHistory,
  GetQueueHistory,
  UpdateQueue,
  UpdateSubscription,
  UpdateTrend,
} from "@/api/apiWebbeat";
import LineChart from "@/components/Line.vue";
import { dayjsEx, fromatDateTime } from "@/utils/dayjs";
import SubscriptionHistory from "@/typing/webbeat/subscriptionHistory.class";
import TrendHistory from "@/typing/webbeat/trendHistory.class";
import QueueModel from "@/typing/webbeat/queueModel.class";
import QueueHistory from "@/typing/webbeat/queueHistory.class";
import { hasPermissionSync } from "@/utils/auth";
import { Permission } from "@/typing/permissions.enum";
import TrendUpdateModel from "@/typing/webbeat/trendUpdateModel.class";
import SubscriptionUpdateModel from "@/typing/webbeat/subscriptionUpdate.class";
import QueueUpdateModel from "@/typing/webbeat/queueUpdateModel.class";
import AlertConfigHistoryResponseModel from "@/typing/webbeat/alertConfigHistoryResponseModel.class";

@Component({
  components: {
    Loader,
    Monitor,
    ServerComponent,
    LineChart,
  },
  props: {
    cid: String,
    pid: String,
    model: String,
    projectName: String,
  },
})
export default class ComponentDetail extends Vue {
  isLoading = false;
  displayEditing = false;
  itemModel = new InfraComponent();
  newSelectedMonitorName = "";
  componentName = "";
  componentDescription = "";
  componentDescriptionGraph = "";
  newComponentDescription = "";
  newComponentDescriptionGraph = "";
  servers: Server[] = [];
  trends: Trend[] = [];
  subscriptions: Subscription[] = [];
  queues: QueueModel[] = [];
  orderedList: (Trend | Subscription | QueueModel)[] = [];
  isDisrupted = true;
  discriptionEditing = false;
  discriptionGraphEditing = false;
  selectedMonitorName = "";
  selectedMonitor = false;
  selectedId = 0;
  chartData: ChartDataModel[] = [];
  chartDataLine: any[] = [];
  chartLabels: string[] = [];
  chartDataSet: any;
  chartOptions: any;
  chartGap: any[] = [];
  chartLower: any[] = [];
  chartUpper: any[] = [];
  highestValue = 0;
  isTrendSelected = false;
  isSubscriptionSelected = false;
  isQueueSelected = false;
  trend: Trend = new Trend();
  queueModel: QueueModel = new QueueModel();
  subscription: Subscription = new Subscription();
  subscriptionHistory: SubscriptionHistory = new SubscriptionHistory();
  queueHistory: QueueHistory = new QueueHistory();
  trendHistory: TrendHistory = new TrendHistory();

  updateTrend: TrendUpdateModel = new TrendUpdateModel();
  updateSubscription: SubscriptionUpdateModel = new SubscriptionUpdateModel();
  updateQueue: QueueUpdateModel = new QueueUpdateModel();
  healthMonitorName = "";

  pastTimeSelected = 7;
  pastTimeOptions = [
    { item: 1, name: "24 uur" },
    { item: 7, name: "Week" },
    { item: 14, name: "Twee weken" },
    { item: 30, name: "Maand" },
  ];
  boundariesLabel = "";
  messageTypeSelected = "Active";
  messageTypeOptions = ["Active", "Dead Letter", "Scheduled Msgs"];
  lowerBound = 0;
  upperBound = 0;
  modalShow = false;
  modalInfoShow = false;
  alertConfigHistories: AlertConfigHistoryResponseModel[] = [];

  isAdminAppUpdate(): boolean {
    return hasPermissionSync(Permission.adminappUpdate);
  }

  async mounted(): Promise<void> {
    this.isLoading = true;
    try {
      this.scrollToTop();
      this.itemModel = new InfraComponent(JSON.parse(this.$props.model));
      this.componentName = this.itemModel.name;
      this.componentDescription = this.newComponentDescription = this.itemModel.description;
      this.isDisrupted = this.itemModel.isDisrupted;
      this.itemModel.servers.forEach((item: Server) => {
        this.servers?.push(item);
      });

      this.itemModel.trends.forEach((item: Trend) => {
        this.trends?.push(new Trend(item));
      });

      this.itemModel.subscriptions.forEach((item: Subscription) => {
        this.subscriptions?.push(new Subscription(item));
      });

      this.itemModel.queues.forEach((item: QueueModel) => {
        this.queues?.push(new QueueModel(item));
      });

      const list = [...this.trends, ...this.subscriptions, ...this.queues];
      this.orderedList = list.sort((a, b) => a.order - b.order);
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.isLoading = false;
  }

  isTrend(item: any) {
    return item instanceof Trend;
  }

  isSubscription(item: any) {
    return item instanceof Subscription;
  }

  isQueueModel(item: any) {
    return item instanceof QueueModel;
  }

  getProjectName() {
    return this.$props.projectName;
  }

  editDescription() {
    this.discriptionEditing = !this.discriptionEditing;
  }

  editDescriptionGraph() {
    this.discriptionGraphEditing = !this.discriptionGraphEditing;
  }

  cancelComponent() {
    this.newComponentDescription = this.componentDescription;
    this.discriptionEditing = false;
  }

  cancelMonitor() {
    this.displayEditing = false;
    this.newSelectedMonitorName = this.selectedMonitorName;
  }

  cancelMonitorGraphComponent() {
    this.newComponentDescriptionGraph = this.componentDescriptionGraph;
    this.discriptionGraphEditing = false;
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scrolling smooth
    });
  }

  async selectTrend(item: Trend) {
    try {
      this.isLoading = true;
      const result = await GetTrendHistory(item.organisationId, item.displayName, this.pastTimeSelected);
      const trendGraph = result.data.body;
      this.chartData = result.data.body.history.values.map(
        (x) =>
          new ChartDataModel({
            date: x.receivedAtDate.toLocaleString("MM/dd"),
            value: x.count,
            receivedAtDate: x.receivedAtDate,
            formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
          }),
      );

      this.chartDataLine = [];
      this.chartLower = [];
      this.chartUpper = [];
      this.chartGap = [];
      this.chartLabels = [];
      this.healthMonitorName = trendGraph.healthMonitorName;
      this.highestValue = 0;
      this.chartData.forEach((chart: ChartDataModel) => {
        this.chartDataLine.push(chart.value);
        this.chartLabels.push(chart.formattedDate);
        this.chartLower.push(item.lowerBound);
        this.chartUpper.push(item.upperBound);

        if (chart.value > this.highestValue) {
          this.highestValue = chart.value;
        }
      });

      if (trendGraph.upperBound > this.highestValue) {
        this.highestValue = trendGraph.upperBound;
      }
      this.highestValue = this.highestValue * 1.1;

      this.highestValue = parseInt(parseFloat(this.highestValue.toString()).toFixed(0));

      this.chartData.forEach((chart: ChartDataModel) => {
        if (chart.value > trendGraph.lowerBound && chart.value < trendGraph.upperBound) {
          this.chartGap.push(NaN);
        } else {
          this.chartGap.splice(this.chartGap.length - 1, 1, this.highestValue);
          this.chartGap.push(this.highestValue);
        }
      });

      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: this.highestValue,
            stepSize: 100,
            reverse: false,
          },
        },
      };
      this.chartDataSet = {
        labels: this.chartLabels,
        datasets: [
          {
            label: "Chart data",
            data: this.chartDataLine,
            borderColor: "#61719D",
            backgroundColor: "#61719D",
            cubicInterpolationMode: "monotone",
          },
          {
            label: "Lower bound",
            data: this.chartLower,
            borderColor: "#d2d2d2",
            borderDash: [5, 5],
            pointStyle: "dash",
            hitRadius: 0,
            pointRadius: 0,
          },
          {
            label: "Upper bound",
            data: this.chartUpper,
            borderColor: "#d2d2d2",
            borderDash: [5, 5],
            hitRadius: 0,
            pointRadius: 0,
            fill: "-1",
          },
          {
            data: this.chartGap,
            backgroundColor: "#FF1E044D",
            borderColor: "#FF1E044D",
            hitRadius: 0,
            pointRadius: 0,
            fill: true,
          },
        ],
      };

      this.newComponentDescriptionGraph = this.componentDescriptionGraph = trendGraph.description ?? "";
      this.selectedMonitorName = this.newSelectedMonitorName = trendGraph.displayName;
      this.selectedMonitor = true;
      this.selectedId = trendGraph.id;
      this.trend = item;
      this.isTrendSelected = true;
      this.isSubscriptionSelected = false;
      this.isQueueSelected = false;
      this.boundariesLabel = trendGraph.lowerBound.toString() + " - " + trendGraph.upperBound.toString();
      this.lowerBound = trendGraph.lowerBound;
      this.upperBound = trendGraph.upperBound;
      this.alertConfigHistories = [];
      result.data.body.alertConfigHistory?.forEach((item: AlertConfigHistoryResponseModel) => {
        this.alertConfigHistories.push(new AlertConfigHistoryResponseModel(item));
      });
      this.scrollToTop();
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    } finally {
      this.isLoading = false;
    }
  }

  async selectSubscription(item: Subscription) {
    try {
      this.isLoading = true;
      const result = await GetSubscriptionHistory(item.organisationId, item.displayName, this.pastTimeSelected);

      this.subscriptionHistory = result.data.body.history;
      const subGraph = result.data.body;
      this.newComponentDescriptionGraph = this.componentDescriptionGraph = subGraph.description ?? "";
      this.selectedMonitorName = this.newSelectedMonitorName = subGraph.displayName;
      this.selectedMonitor = true;
      this.selectedId = subGraph.id;
      this.subscription = item;
      this.isTrendSelected = false;
      this.isSubscriptionSelected = true;
      this.isQueueSelected = false;
      this.healthMonitorName = subGraph.healthMonitorName;
      this.alertConfigHistories = [];
      result.data.body.alertConfigHistory?.forEach((item: AlertConfigHistoryResponseModel) => {
        this.alertConfigHistories.push(new AlertConfigHistoryResponseModel(item));
      });
      this.loadDataSubscripionGraph();
      this.scrollToTop();
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    } finally {
      this.isLoading = false;
    }
  }

  async selectQueue(item: QueueModel) {
    try {
      this.isLoading = true;
      const result = await GetQueueHistory(item.organisationId, item.displayName, this.pastTimeSelected);

      this.queueHistory = result.data.body.history;
      const queueGraph = result.data.body;
      this.newComponentDescriptionGraph = this.componentDescriptionGraph = queueGraph.description ?? "";
      this.selectedMonitorName = this.newSelectedMonitorName = queueGraph.displayName;
      this.selectedMonitor = true;
      this.selectedId = queueGraph.id;
      this.queueModel = item;
      this.isTrendSelected = false;
      this.isSubscriptionSelected = false;
      this.isQueueSelected = true;
      this.healthMonitorName = queueGraph.healthMonitorName;
      this.alertConfigHistories = [];
      result.data.body.alertConfigHistory?.forEach((item: AlertConfigHistoryResponseModel) => {
        this.alertConfigHistories.push(new AlertConfigHistoryResponseModel(item));
      });

      this.loadDataQueueModelGraph();
      this.scrollToTop();
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    } finally {
      this.isLoading = false;
    }
  }

  async changeOption() {
    if (this.isTrendSelected) {
      this.selectTrend(this.trend);
    }

    if (this.isSubscriptionSelected) {
      this.selectSubscription(this.subscription);
    }

    if (this.isQueueSelected) {
      this.selectQueue(this.queueModel);
    }
  }

  async changeOptionMessageType() {
    if (this.isSubscriptionSelected) {
      this.loadDataSubscripionGraph();
    } else {
      this.loadDataQueueModelGraph();
    }
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }

  toggleInfo() {
    this.modalInfoShow = !this.modalInfoShow;
  }

  async loadDataSubscripionGraph() {
    try {
      this.isLoading = true;

      if (this.messageTypeSelected === "Active") {
        this.chartData = this.subscriptionHistory.values.map(
          (x) =>
            new ChartDataModel({
              date: x.receivedAtDate.toLocaleString("MM/dd"),
              value: x.activeMessageCount,
              receivedAtDate: x.receivedAtDate,
              formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
            }),
        );
      }

      if (this.messageTypeSelected === "Dead Letter") {
        this.chartData = this.subscriptionHistory.values.map(
          (x) =>
            new ChartDataModel({
              date: x.receivedAtDate.toLocaleString("MM/dd"),
              value: x.deadLetterMessageCount,
              receivedAtDate: x.receivedAtDate,
              formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
            }),
        );
      }

      if (this.messageTypeSelected === "Scheduled Msgs") {
        this.chartData = this.subscriptionHistory.values.map(
          (x) =>
            new ChartDataModel({
              date: x.receivedAtDate.toLocaleString("MM/dd"),
              value: x.scheduledMessageCount,
              receivedAtDate: x.receivedAtDate,
              formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
            }),
        );
      }

      this.chartDataLine = [];
      this.chartUpper = [];
      this.chartGap = [];
      this.chartLabels = [];

      this.highestValue = 0;
      let threshold = 0;

      if (this.messageTypeSelected === "Active") {
        threshold = this.subscription.activeMessageTreshold;
      }

      if (this.messageTypeSelected === "Dead Letter") {
        threshold = this.subscription.deadLetterMessageTreshold;
      }

      if (this.messageTypeSelected === "Scheduled Msgs") {
        threshold = this.subscription.scheduledMessageTreshold;
      }

      this.chartData.forEach((chart: ChartDataModel) => {
        this.chartDataLine.push(chart.value);
        this.chartLabels.push(chart.formattedDate);
        this.chartUpper.push(threshold);

        if (chart.value > this.highestValue) {
          this.highestValue = chart.value;
        }
      });

      if (threshold > this.highestValue) {
        this.highestValue = threshold;
      }

      this.highestValue = this.highestValue * 1.1;
      this.lowerBound = 0;
      this.upperBound = threshold;
      this.highestValue = parseInt(parseFloat(this.highestValue.toString()).toFixed(0));

      this.chartData.forEach((chart: ChartDataModel) => {
        if (chart.value < threshold) {
          this.chartGap.push(NaN);
        } else {
          this.chartGap.splice(this.chartGap.length - 1, 1, this.highestValue);
          this.chartGap.push(this.highestValue);
        }
      });
      this.boundariesLabel = 0 + " - " + threshold;

      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: this.highestValue,
            stepSize: 100,
            reverse: false,
          },
        },
      };
      this.chartDataSet = {
        labels: this.chartLabels,
        datasets: [
          {
            label: "Chart data",
            data: this.chartDataLine,
            borderColor: "#61719D",
            backgroundColor: "#61719D",
            cubicInterpolationMode: "monotone",
          },
          {
            label: "Upper bound",
            data: this.chartUpper,
            borderColor: "#d2d2d2",
            borderDash: [5, 5],
            hitRadius: 0,
            pointRadius: 0,
            fill: true,
          },
          {
            data: this.chartGap,
            backgroundColor: "#FF1E044D",
            borderColor: "#FF1E044D",
            hitRadius: 0,
            pointRadius: 0,
            fill: true,
          },
        ],
      };
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    } finally {
      this.isLoading = false;
    }
  }

  async loadDataQueueModelGraph() {
    try {
      this.isLoading = true;

      if (this.messageTypeSelected === "Active") {
        this.chartData = this.queueHistory.values.map(
          (x) =>
            new ChartDataModel({
              date: x.receivedAtDate.toLocaleString("MM/dd"),
              value: x.activeMessageCount,
              receivedAtDate: x.receivedAtDate,
              formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
            }),
        );
      }

      if (this.messageTypeSelected === "Dead Letter") {
        this.chartData = this.queueHistory.values.map(
          (x) =>
            new ChartDataModel({
              date: x.receivedAtDate.toLocaleString("MM/dd"),
              value: x.deadLetterMessageCount,
              receivedAtDate: x.receivedAtDate,
              formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
            }),
        );
      }

      if (this.messageTypeSelected === "Scheduled Msgs") {
        this.chartData = this.queueHistory.values.map(
          (x) =>
            new ChartDataModel({
              date: x.receivedAtDate.toLocaleString("MM/dd"),
              value: x.scheduledMessageCount,
              receivedAtDate: x.receivedAtDate,
              formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
            }),
        );
      }

      this.chartDataLine = [];
      this.chartUpper = [];
      this.chartGap = [];
      this.chartLabels = [];

      this.highestValue = 0;
      let threshold = 0;

      if (this.messageTypeSelected === "Active") {
        threshold = this.queueModel.activeMessageTreshold;
      }

      if (this.messageTypeSelected === "Dead Letter") {
        threshold = this.queueModel.deadLetterMessageTreshold;
      }

      if (this.messageTypeSelected === "Scheduled Msgs") {
        threshold = this.queueModel.scheduledMessageTreshold;
      }
      this.lowerBound = 0;
      this.upperBound = threshold;
      this.chartData.forEach((chart: ChartDataModel) => {
        this.chartDataLine.push(chart.value);
        this.chartLabels.push(chart.formattedDate);
        this.chartUpper.push(threshold);

        if (chart.value > this.highestValue) {
          this.highestValue = chart.value;
        }
      });

      if (threshold > this.highestValue) {
        this.highestValue = threshold;
      }

      this.highestValue = this.highestValue * 1.1;

      this.highestValue = parseInt(parseFloat(this.highestValue.toString()).toFixed(0));

      this.chartData.forEach((chart: ChartDataModel) => {
        if (chart.value < threshold) {
          this.chartGap.push(NaN);
        } else {
          this.chartGap.splice(this.chartGap.length - 1, 1, this.highestValue);
          this.chartGap.push(this.highestValue);
        }
      });
      this.boundariesLabel = 0 + " - " + threshold;

      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: this.highestValue,
            stepSize: 100,
            reverse: false,
          },
        },
      };
      this.chartDataSet = {
        labels: this.chartLabels,
        datasets: [
          {
            label: "Chart data",
            data: this.chartDataLine,
            borderColor: "#61719D",
            backgroundColor: "#61719D",
            cubicInterpolationMode: "monotone",
          },
          {
            label: "Upper bound",
            data: this.chartUpper,
            borderColor: "#d2d2d2",
            borderDash: [5, 5],
            hitRadius: 0,
            pointRadius: 0,
            fill: true,
          },
          {
            data: this.chartGap,
            backgroundColor: "#FF1E044D",
            borderColor: "#FF1E044D",
            hitRadius: 0,
            pointRadius: 0,
            fill: true,
          },
        ],
      };
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    } finally {
      this.isLoading = false;
    }
  }

  async saveDescription() {
    this.isLoading = true;
    try {
      const result = await UpdateComponentDescription(this.itemModel.id, this.newComponentDescription);
      this.componentDescription = this.newComponentDescription = result.data.body.description;
      this.$store.dispatch(storeTypes.actions.UPDATE_COMPONENT, {
        model: result.data,
      });
      this.discriptionEditing = false;
      this.displayEditing = false;
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    this.isLoading = false;
  }

  async save() {
    this.isLoading = true;
    try {
      if (this.isTrendSelected) {
        this.updateTrend.displayName = this.newSelectedMonitorName;
        this.updateTrend.description = this.newComponentDescriptionGraph;
        this.updateTrend.lowerBound = Number(this.lowerBound);
        this.updateTrend.upperBound = Number(this.upperBound);
        this.updateTrend.healthMonitorName = this.trend.healthMonitorName;
        const result = await UpdateTrend(this.trend.organisationId, this.trend.id, this.updateTrend);

        this.componentDescriptionGraph = this.newComponentDescriptionGraph = result.data.body.description ?? "";
        this.selectedMonitorName = this.newSelectedMonitorName = result.data.body.displayName;
        this.lowerBound = result.data.body.lowerBound;
        this.upperBound = result.data.body.upperBound;
        this.boundariesLabel = this.lowerBound.toString() + " - " + this.upperBound.toString();
      }

      if (this.isSubscriptionSelected) {
        this.updateSubscription.displayName = this.newSelectedMonitorName;
        this.updateSubscription.description = this.newComponentDescriptionGraph;

        if (this.messageTypeSelected === "Active") {
          this.updateSubscription.activeMessageThreshold = Number(this.upperBound);
        }

        if (this.messageTypeSelected === "Dead Letter") {
          this.updateSubscription.deadLetterMessageThreshold = Number(this.upperBound);
        }

        if (this.messageTypeSelected === "Scheduled Msgs") {
          this.updateSubscription.scheduledMessageThreshold = Number(this.upperBound);
        }

        const result = await UpdateSubscription(this.subscription.organisationId, this.subscription.id, this.updateSubscription);

        this.componentDescriptionGraph = this.newComponentDescriptionGraph = result.data.body.description ?? "";
        this.selectedMonitorName = this.newSelectedMonitorName = result.data.body.displayName;
        this.loadDataSubscripionGraph();
      }

      if (this.isQueueSelected) {
        this.updateQueue.displayName = this.newSelectedMonitorName;
        this.updateQueue.description = this.newComponentDescriptionGraph;

        if (this.messageTypeSelected === "Active") {
          this.updateQueue.activeMessageThreshold = Number(this.upperBound);
        }

        if (this.messageTypeSelected === "Dead Letter") {
          this.updateQueue.deadLetterMessageThreshold = Number(this.upperBound);
        }

        if (this.messageTypeSelected === "Scheduled Msgs") {
          this.updateQueue.scheduledMessageThreshold = Number(this.upperBound);
        }

        const result = await UpdateQueue(this.queueModel.organisationId, this.queueModel.id, this.updateQueue);
        this.componentDescriptionGraph = this.newComponentDescriptionGraph = result.data.body.description ?? "";
        this.selectedMonitorName = this.newSelectedMonitorName = result.data.body.displayName;
        this.loadDataQueueModelGraph();
      }

      this.discriptionGraphEditing = false;
      this.displayEditing = false;
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    this.isLoading = false;
  }

  editDisplayName() {
    this.displayEditing = !this.displayEditing;
  }
}
