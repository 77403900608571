




















































import Vue from "vue";
import Component from "vue-class-component";
import { GetTeamMemberCreateData, CreateTeamMember } from "@/api/apiWebbeat";
import ToastrHelper from "@/helpers/toastHelper";
import TeamMemberCreateResponseModel from "@/typing/webbeat/teamMemberCreateResponseModel.class";
import Organisation from "@/typing/webbeat/organisation.class";
import Product from "@/typing/webbeat/product.class";
import { User } from "@auth0/auth0-spa-js";
import TeamMemberCreateModel from "@/typing/webbeat/teamMemberCreateModel.class";
import Modal from "@/components/Modal.vue";

@Component({
  components: {
    Modal,
  },
})
export default class CreateTeamMemberComponent extends Vue {
  createTeamMemberModel = new TeamMemberCreateModel();
  selected = "";
  selectedProduct = "";
  showModal = false;
  model = new TeamMemberCreateResponseModel();
  organisations = Array<Organisation>();
  products = Array<Product>();
  users = Array<User>();

  async createTeamMember() {
    try {
      this.createTeamMemberModel.productId = parseInt(this.selectedProduct);
      const result = await CreateTeamMember(this.createTeamMemberModel);
      if (result.data.hasErrors) {
        ToastrHelper.successToastr(result.data.errors[0].message, this);
      } else {
        ToastrHelper.successToastr("Teammember is succesvol aangemaakt", this);
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.toggleModal();
  }

  async mounted(): Promise<void> {
    try {
      const result = await GetTeamMemberCreateData();
      this.model = result.data.body;
      this.organisations = result.data.body.organisations;
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  async organisationChanged() {
    this.selectedProduct = "";
    this.createTeamMemberModel.userId = "";
    this.products.splice(0, this.products.length);
    this.users.splice(0, this.users.length);
    this.products = this.model.products.filter((x) => x.organisationId == parseInt(this.selected));

    this.users = this.model.users.filter((x) => x.organisationId == 2 || parseInt(this.selected));
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
