export default class TeamMemberCreateModel {
  role: string;
  description: string;
  isPublicProfile: boolean;
  productId: number;
  userId: string;

  constructor(t?: TeamMemberCreateModel) {
    this.role = t?.role ?? "";
    this.description = t?.description ?? "";
    this.productId = t?.productId ?? 0;
    this.isPublicProfile = t?.isPublicProfile ?? false;
    this.userId = t?.userId ?? "";
  }
}
