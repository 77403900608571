
































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import ToastrHelper from "@/helpers/toastHelper";
import Product from "@/typing/webbeat/product.class";
import InfraComponent from "@/typing/webbeat/component.class";
import Loader from "@/components/Loader.vue";
import TeamMember from "@/typing/webbeat/teamMember.class";
import Discipline from "@/typing/webbeat/discipline.class";
import Monitor from "@/components/Monitor.vue";
import ServerComponent from "@/components/ServerComponent.vue";
import Trend from "@/typing/webbeat/trend.class";
import Subscription from "@/typing/webbeat/subscription.class";
import QueueModel from "@/typing/webbeat/queueModel.class";
import AlertConfigHistoryResponseModel from "@/typing/webbeat/alertConfigHistoryResponseModel.class";

import { GetProductById, ProductToggleEmailNotification, ProductTogglePushNotifications } from "@/api/apiWebbeat";
import storeTypes from "@/store/storeTypes";

@Component({
  components: {
    Loader,
    Monitor,
    ServerComponent,
  },
  props: {
    id: String,
  },
})
export default class ProductDetail extends Vue {
  serviceLevelAgreement = "";
  isLoading = false;
  items: InfraComponent[] = [];
  itemModel: Product = new Product();
  contacts: TeamMember[] = [];
  techSupports: string[] = [];
  isEmailEnabled = false;
  isPushEnabled = false;
  projectName = "";
  trends: Trend[] = [];
  subscriptions: Subscription[] = [];
  queues: QueueModel[] = [];
  orderedList: (Trend | Subscription | QueueModel)[] = [];
  alertConfigHistories: AlertConfigHistoryResponseModel[] = [];

  async mounted(): Promise<void> {
    this.isLoading = true;
    try {
      const result = await GetProductById(this.$props.id);

      this.alertConfigHistories = [];

      var historyList = result.data.body.alertConfigHistories?.map((item) => new AlertConfigHistoryResponseModel(item));
      this.alertConfigHistories = historyList ?? [];

      result.data.body.alertConfigHistories?.forEach((item: AlertConfigHistoryResponseModel) => {
        this.alertConfigHistories.push(new AlertConfigHistoryResponseModel(item));
      });

      this.itemModel = new Product(result.data.body);
      this.projectName = this.itemModel.name;
      this.contacts = this.itemModel.teamMembers;
      this.serviceLevelAgreement = this.itemModel.serviceLevelAgreement;
      this.isEmailEnabled = this.itemModel.isEmailEnabled;
      this.isPushEnabled = this.itemModel.isPushEnabled;

      this.itemModel.teamMembers.forEach((item: TeamMember) => {
        item.disciplines.forEach((disepline: Discipline) => {
          if ((disepline.name == "Tech Support" && item.email != null) || "") {
            this.techSupports.push(item.email);
          }
        });
      });

      if (this.techSupports.length == 0) {
        this.techSupports.push("info@nalta.com");
      }
      this.itemModel.components
        .sort((a: InfraComponent, b: InfraComponent) => {
          return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
        })
        .forEach((item: InfraComponent) => {
          this.items.push(new InfraComponent(item));
        });

      this.itemModel.trends.forEach((item: Trend) => {
        this.trends?.push(new Trend(item));
      });

      this.itemModel.subscriptions.forEach((item: Subscription) => {
        this.subscriptions?.push(new Subscription(item));
      });

      this.itemModel.queues.forEach((item: QueueModel) => {
        this.queues?.push(new QueueModel(item));
      });

      const list = [...this.trends, ...this.subscriptions, ...this.queues];
      this.orderedList = list.sort((a, b) => a.order - b.order);
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.isLoading = false;
  }

  onImgError(e: any, item: any) {
    item.imgError = true;
  }

  async contactSpecialist() {
    var mailTo = "mailto:";
    this.techSupports.forEach((item: string) => {
      mailTo = mailTo + "; " + item;
    });
    window.location.assign(mailTo);
  }

  async checkEmail() {
    this.isLoading = true;
    try {
      const result = await ProductToggleEmailNotification(this.$props.id);
      this.itemModel.isEmailEnabled = this.isEmailEnabled = result.data.body.isEmailEnabled;
      this.$store.dispatch(storeTypes.actions.UPDATE_PRODUCT, this.itemModel);
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    this.isLoading = false;
  }

  async checkPushNotification() {
    this.isLoading = true;
    try {
      const result = await ProductTogglePushNotifications(this.$props.id);
      this.itemModel.isPushEnabled = this.isPushEnabled = result.data.body.isPushEnabled;
      this.$store.dispatch(storeTypes.actions.UPDATE_PRODUCT, this.itemModel);
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    this.isLoading = false;
  }

  async goToComponentDetail(item: InfraComponent) {
    this.$router.push({
      name: "ComponentDetail",
      params: {
        model: JSON.stringify(item),
        projectName: this.projectName,
        pid: item.productId.toString(),
        cid: item.id.toString(),
      },
    });
  }

  componentIsDisrupted() {
    return this.items.filter((item) => item.isDisrupted).length > 0;
  }

  isTrend(item: any) {
    return item instanceof Trend;
  }

  isSubscription(item: any) {
    return item instanceof Subscription;
  }

  isQueueModel(item: any) {
    return item instanceof QueueModel;
  }

  getTrendPercentage(item: Trend): number {
    return Math.round((100 / (item.upperBound - item.lowerBound)) * item.lastKnownValue);
  }

  getSubscriptionPercentage(item: Subscription): number {
    return Math.round((100 / item.activeMessageTreshold) * item.activeMessageCount);
  }

  getQueuePercentage(item: QueueModel): number {
    return Math.round((100 / item.activeMessageTreshold) * item.activeMessageCount);
  }

  async goToGraphDetailTrend(item: Trend) {
    this.$router.push({
      name: "GraphDetail",
      params: {
        trendProp: JSON.stringify(item),
      },
    });
  }

  async goToGraphDetailQueue(item: QueueModel) {
    this.$router.push({
      name: "GraphDetail",
      params: {
        queueModelProp: JSON.stringify(item),
      },
    });
  }

  async goToGraphDetailSubscription(item: Subscription) {
    this.$router.push({
      name: "GraphDetail",
      params: {
        subscriotionProp: JSON.stringify(item),
      },
    });
  }

  get getComponentLength(): string {
    return `${this.items.filter((item) => !item.isDisrupted).length} / ${this.items.length}`;
  }

  get getMonitorLength(): string {
    return `${this.orderedList.filter((item) => !item.isDisrupted).length} / ${this.orderedList.length}`;
  }
}
