import Version from "./version.class";

export default class BuildPackage {
  key: string;
  name: string;
  versions: Version[];
  authors: string;

  constructor(p?: BuildPackage) {
    this.key = p?.key ?? "";
    this.name = p?.name ?? "";
    this.versions = [];
    this.authors = p?.authors ?? "";

    if (p?.versions) {
      this.versions = p?.versions.map((x) => new Version(x, this.name));
    }
  }
}
