import { render, staticRenderFns } from "./CreateQueueComponent.vue?vue&type=template&id=33e38942&scoped=true&"
import script from "./CreateQueueComponent.vue?vue&type=script&lang=ts&"
export * from "./CreateQueueComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e38942",
  null
  
)

export default component.exports