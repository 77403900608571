enum IconVariant {
  CircleDash = "dash-circle",
  CircleCross = "x-circle",
  CircleCheck = "check-circle",
}

export function getPackageStatusIconClass(item: any): string {
  if (item == null || item.approved == null || (typeof item.approvedBy !== "undefined" && !item.approvedBy && !item.approved)) {
    return IconVariant.CircleDash;
  }
  if (!item.approved) {
    return IconVariant.CircleCross;
  }
  return IconVariant.CircleCheck;
}
