



































































































import Component from "vue-class-component";
import LighthouseView from "@/views/lighthouse/LighthouseView";
import { GetPackageBuild } from "@/api/apiLightHouse";
import Build from "@/typing/lighthouse/build.class";
import Loader from "@/components/Loader.vue";
import PackageDetail from "@/typing/lighthouse/packageDetails.class";
import ToastrHelper from "@/helpers/toastHelper";
import { getPackageStatusIconClass } from "@/helpers/iconHelper";
import Multiselect from "vue-multiselect";
import storeTypes from "@/store/storeTypes";
import { hasPermission } from "@/utils/auth";
import { Permission } from "@/typing/permissions.enum";
import getQueryParams from "@/utils/getQueryParams";
import { EventBus } from "@/utils/eventBus/eventBus";
import { EventBusEvent } from "@/utils/eventBus/eventBusEvent.enum";

@Component({
  components: {
    Loader,
    Multiselect,
  },
})
export default class PackageDetailView extends LighthouseView {
  get shownItems(): Build[] {
    let items = [...this.items];
    if (this.filterOnLastBuild) {
      const toReturnItems: any[] = [];
      items.forEach((item) => {
        if (item.isLatestBuild) {
          toReturnItems.push(item);
        }
      });
      items = toReturnItems;
    }

    if (this.filteredBranches.length > 0) {
      const filteredBranchValues = this.filteredBranches.map((item: any) => item.value);
      items = items.filter((i) => filteredBranchValues.includes(i.branch));
    }

    return items;
  }

  get packageDetailModel(): PackageDetail {
    const filtered = this.$store.state.packagesDetail.filter((x) => x.key == this.packageKey);

    if (filtered.length === 0) {
      return new PackageDetail();
    }

    return filtered[0];
  }

  get packageName(): string {
    return this.$store.state.packageName;
  }

  get approvedPackage(): boolean {
    return this.packageDetailModel.approved;
  }

  get totalRows(): number {
    return this.items.length;
  }

  get isVulnerable(): boolean {
    const version = this.$store.state.versions.find((x) => x.version === this.packageDetailModel.version);

    return version?.isVulnerable ?? false;
  }

  backVisible = true;
  filteredBranches: any[] = [];
  options = [
    { label: "master", value: "master" },
    { label: "develop", value: "develop" },
    { label: "production", value: "production" },
    { label: "accept", value: "accept" },
  ];
  filterOnLastBuild = false;
  items: Build[] = [];
  isLoading = false;
  fields: any[] = [
    {
      key: "name",
      label: this.$t("BuildName") as string,
    },
    {
      key: "buildNumber",
      label: this.$t("BuildNumber") as string,
    },
    {
      key: "isLatestBuild",
      label: this.$t("LatestVersion") as string,
      formatter: (value: any) => {
        return value ? (this.$t("Yes") as string) : (this.$t("No") as string);
      },
    },
    {
      key: "timestampFormat",
      label: this.$t("LastBuild") as string,
      sortable: true,
    },
    { key: "branch", label: this.$t("Branch") as string, sortable: true },
  ];
  currentPage = 1;
  pageSize = 25;
  pageOptions: number[] = [25, 50, 100, 200, 500];
  outlined = true;
  hasPackageApprovalPermission = false;
  perPage: string = this.$t("PerPage") as string;
  packageKey = "";
  getPackageStatusIconClass = getPackageStatusIconClass;

  async mounted(): Promise<void> {
    await this._mount();

    EventBus.$off(EventBusEvent.QueryStringChanged);

    EventBus.$on(EventBusEvent.QueryStringChanged, () => {
      setTimeout(() => {
        this._mount();
      }, 50);
    });
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusEvent.QueryStringChanged);
  }

  async goToBuildDetail(item: Build) {
    this.$router.push({ path: `/builds/detail?key=${item.key}` });
  }

  private async _mount(): Promise<void> {
    this.packageKey = getQueryParams("packageKey");

    if (!this.packageKey) {
      this.$router.push({ path: "/home" });
    }

    try {
      this.isLoading = true;

      await this.$store.dispatch(storeTypes.actions.GET_PACKAGEDETAIL, this.packageKey);

      this.hasPackageApprovalPermission = await hasPermission(Permission.packageApproval);

      await this._fetchPackageBuild();
    } catch (e) {
      console.error(e);
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    this.isLoading = false;

    EventBus.$emit(EventBusEvent.Navigated, {
      name: this.packageName + " " + this.packageDetailModel.version,
    });
  }

  private async _fetchPackageBuild(): Promise<void> {
    const result = await GetPackageBuild(this.packageKey);
    this.items = result.data.body
      ?.sort((a: Build, b: Build) => {
        const sortResult = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

        return sortResult;
      })
      .map((item: Build) => new Build(item));
  }
}
