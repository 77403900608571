























































































































































import Component from "vue-class-component";
import LighthouseView from "@/views/lighthouse/LighthouseView";
import { PutPackageApprove, PutPackageDecline } from "@/api/apiLightHouse";
import PackageDetail from "@/typing/lighthouse/packageDetails.class";
import Loader from "@/components/Loader.vue";
import ToastrHelper from "@/helpers/toastHelper";
import { getPackageStatusIconClass } from "@/helpers/iconHelper";
import storeTypes from "@/store/storeTypes";
import { hasPermission } from "@/utils/auth";
import { Permission } from "@/typing/permissions.enum";
import Modal from "@/components/Modal.vue";
import getQueryParams from "@/utils/getQueryParams";
import { EventBus } from "@/utils/eventBus/eventBus";
import { EventBusEvent } from "@/utils/eventBus/eventBusEvent.enum";
import PackageRequest from "@/typing/lighthouse/packageRequest.class";

@Component({
  components: {
    Loader,
    Modal,
  },
})
export default class PackageView extends LighthouseView {
  declare $refs: any;

  get packageName(): string {
    return this.$store.state.packageName;
  }
  get packageSummary(): string {
    return this.$store.state.packageSummary;
  }
  get packageLatestVersion(): string {
    return this.$store.state.packageLatestVersion;
  }
  get packageAuthors(): string {
    return this.$store.state.packageAuthors;
  }
  get items(): PackageDetail[] {
    if (this.$store.state.packagesDetail.length === 0) {
      return [];
    }
    return this.$store.state.packagesDetail;
  }
  get sortOptions(): any {
    return this.fields
      .filter((f) => f.sortable)
      .map((f) => {
        return { text: f.label, value: f.key };
      });
  }

  isLoading = false;
  isModalDeclineShow = false;
  isModalApproveShow = false;
  showModal = false;
  backVisible = true;
  packageRequest = new PackageRequest();
  fields: any[] = [
    {
      key: "version",
      label: this.$t("PackageVersion") as string,
      sortable: true,
    },
    {
      key: "approved",
      label: "Approval",
      formatter: (value: any) => {
        return value ? (this.$t("IsApproved") as string) : (this.$t("IsNotApproved") as string);
      },
    },
    {
      key: "approvedBy",
      label: this.$t("ApprovalBy") as string,
      sortable: true,
    },
    {
      key: "riskScore",
      label: this.$t("RiskScore") as string,
      sortable: true,
    },
    { key: "actions", label: this.$t("Actions") as string },
  ];
  totalRows = 1;
  currentPage = 1;
  pageSize = 25;
  pageOptions: number[] = [25, 50, 100, 200, 500];
  filter: any = this.$route.query.q ?? null;
  filterOn = "";
  outlined = true;
  typeToSearch: string = this.$t("TypeToSearch") as string;
  filterLabel: string = this.$t("Filter") as string;
  filterOnLabel: string = this.$t("FilterOn") as string;
  perPage: string = this.$t("PerPage") as string;
  hasPackageApprovalPermission = false;
  getPackageStatusIconClass = getPackageStatusIconClass;
  selectedPackage: PackageDetail = new PackageDetail();
  riskScore = "1";

  async mounted(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.$store.state.packages.length === 0) {
        await this.$store.dispatch(storeTypes.actions.GET_PACKAGES);
      }

      await this.$store.dispatch(storeTypes.actions.GET_PACKAGEDETAIL, getQueryParams("packageKey"));
    } catch (e) {
      console.error(e);
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.totalRows = this.items.length;
    this.hasPackageApprovalPermission = await hasPermission(Permission.packageApproval);
    this.packageRequest.packageName = this.packageName;
    this.isLoading = false;
    EventBus.$emit(EventBusEvent.Navigated, {
      name: this.packageName,
    });
  }

  onFiltered(filteredItems: any) {
    // Update Query Parameters in URL with seach value (for history back)
    this.setHistoryQueryParams({ q: this.filter });

    // Update navigation history
    EventBus.$emit(EventBusEvent.Navigated, {
      name: this.packageName,
      path: location.pathname + location.search,
      replace: true,
    });

    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  rowClass(item: PackageDetail) {
    if (!item) {
      return;
    }

    var version = this.$store.state.versions.find((x) => x.key == item.key);

    if (version?.isVulnerable) {
      return "table-warning";
    }

    if (item.approvedBy == "" && item.approved == false) {
      return "";
    }

    if (!item.approved) {
      return "table-danger";
    }

    return "table-success";
  }

  buttonVariant(item: PackageDetail) {
    if (item.approved) {
      return "danger";
    }
    return "success";
  }

  buttonTitle(item: PackageDetail) {
    return item.approved ? (this.$t("DeclinePackage") as string) : (this.$t("ApprovePackage") as string);
  }

  showModalDeclinePackage(item: PackageDetail) {
    const packageD = new PackageDetail(item);

    try {
      if (packageD.approved || packageD.approvedBy == "") {
        this.selectedPackage = packageD;
        this.isModalDeclineShow = true;
        this.$nextTick(() => this.$refs.reasonInput.focus());
      }
    } catch (e) {
      console.error(e);
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  async declinePackage(item: PackageDetail, reason: string) {
    const packageD = new PackageDetail(item);

    try {
      if (reason == "") {
        this.$refs["reasonInput"].focus();
      } else {
        const result = await PutPackageDecline(packageD.key, reason);
        packageD.approved = result.data.body.approved;
        packageD.approvedBy = result.data.body.approvedBy;
        packageD.riskScore = result.data.body.riskScore;
        this.$store.dispatch(storeTypes.actions.UPDATE_LIGHTHOUSE_PACKAGE, packageD);
        this.isModalDeclineShow = false;
      }
    } catch (e) {
      console.error(e);
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  showModalApprovePackage(item: PackageDetail) {
    const packageD = new PackageDetail(item);

    try {
      if (!packageD.approved) {
        this.selectedPackage = packageD;
        this.isModalApproveShow = true;

        const lastRiskScore = this.items.find((i) => i.approved && i.riskScore !== null && i.riskScore !== 0)?.riskScore.toString();
        this.riskScore = lastRiskScore || this.riskScore;
        this.$nextTick(() => this.$refs.riskScoreInput.focus());
      }
    } catch (e) {
      console.error(e);
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  async approvePackage(item: PackageDetail, riskScore: string) {
    const packageD = new PackageDetail(item);

    try {
      if (!packageD.approved) {
        const result = await PutPackageApprove(packageD.key, riskScore);
        packageD.approved = result.data.body.approved;
        packageD.approvedBy = result.data.body.approvedBy;
        packageD.riskScore = result.data.body.riskScore;
        this.$store.dispatch(storeTypes.actions.UPDATE_LIGHTHOUSE_PACKAGE, packageD);
        this.isModalApproveShow = false;
      }
    } catch (e) {
      console.error(e);
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  async goToPackageDetail(item: PackageDetail) {
    this.$router.push({ path: `/package/detail?packageKey=${item.key}` });
  }
}
