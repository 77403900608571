import NavHistory from "@/typing/lighthouse/navHistory.class";
import { vueInstance } from "@/utils/vueInstance";

const localStorageEntries = { navHistory: "navHistory" };

function setNavigationHistory(navHistory: any): void {
  sessionStorage.setItem(
    localStorageEntries.navHistory,
    JSON.stringify(navHistory, (key, value) => {
      if (value !== null) return value;
    }),
  );
}

export function getNavigationHistory(): Array<NavHistory> {
  return JSON.parse(sessionStorage.getItem(localStorageEntries.navHistory) ?? "[]");
}

export default function onNavigation(nav: NavHistory): void {
  const path = nav?.path || vueInstance().$router.currentRoute.fullPath;
  if (path.includes("/auth")) {
    return;
  }
  const navHistory = nav.clear ? [] : getNavigationHistory();
  nav.path = path;

  delClear(nav);

  let historyMatchIndex = -1;
  navHistory.every((item: NavHistory, index: number) => {
    if (JSON.stringify(item) === JSON.stringify(nav)) {
      historyMatchIndex = index;
      return false;
    }
    return true;
  });

  if (historyMatchIndex > -1) {
    navHistory.length = historyMatchIndex + 1;
  } else {
    navHistory.push(nav);
  }

  setNavigationHistory(navHistory);

  function delClear(nav: Partial<NavHistory>) {
    delete nav.clear;
  }
}
