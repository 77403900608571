












import getEnv from "@/utils/environment/getEnvironment";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class DevToolbar extends Vue {
  switchingRoles = false;
  open = true;

  get envName(): string {
    return getEnv().envName;
  }

  logEnvironment() {
    this._readableConsoleLog(getEnv());
  }

  logState() {
    this._readableConsoleLog(this.$store.state);
  }

  gotoComponentPlayground(): void {
    this.$router.push(`/dev/components`);
  }

  private _readableConsoleLog(val: any): any {
    console.log(JSON.parse(JSON.stringify(val, (key: any, value: any) => (value === undefined ? null : value))));
  }
}
