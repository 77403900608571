import { Store } from "vuex";
import BaseView from "../BaseView";
import { IState } from "@/typing/state.interface";

export default class LightHouseView extends BaseView {
  declare $store: Store<IState>;

  constructor() {
    super();
  }
}
