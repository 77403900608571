export default class QueueRequestModel {
  queueName: string;
  description: string;
  displayName: string;
  healthMonitorName: string;
  activeMessageTreshold: number;
  deadLetterMessageTreshold: number;
  scheduledMessageTreshold: number;
  organisationId: number;
  addHealthAlertConfig: boolean;
  sizePercentageTreshold: number | null;

  constructor(s?: QueueRequestModel) {
    this.organisationId = s?.organisationId ?? 0;
    this.queueName = s?.queueName ?? "";
    this.displayName = s?.displayName ?? "";
    this.healthMonitorName = s?.healthMonitorName ?? "";
    this.description = s?.description ?? "";
    this.activeMessageTreshold = s?.activeMessageTreshold ?? 0;
    this.deadLetterMessageTreshold = s?.deadLetterMessageTreshold ?? 0;
    this.scheduledMessageTreshold = s?.scheduledMessageTreshold ?? 0;
    this.addHealthAlertConfig = s?.addHealthAlertConfig ?? false;
    this.sizePercentageTreshold = s?.sizePercentageTreshold ?? 0;
  }

  getPostBody() {
    const obj = { ...this };
    if (!this.addHealthAlertConfig) {
      obj.sizePercentageTreshold = null;
    }
  }
}
