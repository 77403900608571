import getEnv from "@/utils/environment/getEnvironment";
import axios, { AxiosResponse } from "axios";
import ProductBase from "@/typing/webbeat/productBase.class";
import Notification from "@/typing/webbeat/notification.class";
import UserProfile from "@/typing/webbeat/userProfile.class";
import NotificationSettings from "@/typing/webbeat/notificationSettings.class";
import InfraComponent from "@/typing/webbeat/component.class";
import Trend from "@/typing/webbeat/trend.class";
import Subscription from "@/typing/webbeat/subscription.class";
import { INaltaAPIResponse } from "@/typing/webbeat/nltAPIResponse.interface";
import UpdateBoundryTrend from "@/typing/webbeat/updateBoundryTrend.class";
import UpdateBoundriesThresholds from "@/typing/webbeat/updateBoundriesThresholds.class";
import QueueModel from "@/typing/webbeat/queueModel.class";
import Product from "@/typing/webbeat/product.class";
import Organisation from "@/typing/webbeat/organisation.class";
import Server from "@/typing/webbeat/server.class";
import SubscriptionRequestModel from "@/typing/webbeat/subscriptionRequestModel.class";
import QueueRequestModel from "@/typing/webbeat/queueRequestModel.class";
import TrendRequestModel from "@/typing/webbeat/trendRequestModel.class";
import ComponentRequestModel from "@/typing/webbeat/componentRequestModel.class";
import Component from "@/typing/webbeat/component.class";
import ProductRequestModel from "@/typing/webbeat/productRequestModel.class";
import ComponentMonitors from "@/typing/webbeat/componentMonitors.class";
import ComponentMonitorsResponseModel from "@/typing/webbeat/componentMonitorsResponseModel.class";
import TeamMemberCreateResponseModel from "@/typing/webbeat/teamMemberCreateResponseModel.class";
import TeamMemberCreateModel from "@/typing/webbeat/teamMemberCreateModel.class";
import User from "@/typing/webbeat/user.class";
import TrendGraph from "@/typing/webbeat/graphs/trendGraph.class";
import SubscriptionGraph from "@/typing/webbeat/graphs/subscriptionGraph.class";
import QueueGraph from "@/typing/webbeat/graphs/queueGraph.class";
import TrendUpdateModel from "@/typing/webbeat/trendUpdateModel.class";
import SubscriptionUpdateModel from "@/typing/webbeat/subscriptionUpdate.class";
import QueueUpdateModel from "@/typing/webbeat/queueUpdateModel.class";

enum Version {
  V1 = "v1",
  V2 = "v2",
}

type Response<T> = AxiosResponse<INaltaAPIResponse<T>>;

function apiNaltaApp(): string {
  return getEnv().apiNaltaApp ?? "";
}

export function GetProducts(version: Version = Version.V1): Promise<Response<ProductBase[]>> {
  return axios.get(`${apiNaltaApp()}${version}/products`);
}

export function GetProductById(id: number, version: Version = Version.V1): Promise<Response<Product>> {
  return axios.get(`${apiNaltaApp()}${version}/products/${id}`);
}

export function GetNotification(version: Version = Version.V1): Promise<Response<Notification[]>> {
  return axios.get(`${apiNaltaApp()}${version}/notification`);
}

export function GetUserProfile(version: Version = Version.V1): Promise<Response<UserProfile>> {
  return axios.get(`${apiNaltaApp()}${version}/users/profile`);
}

export function ProductToggleEmailNotification(id: number, version: Version = Version.V1): Promise<Response<NotificationSettings>> {
  return axios.put(`${apiNaltaApp()}${version}/products/${id}/ToggleEmailNotifications`);
}

export function ProductTogglePushNotifications(id: number, version: Version = Version.V1): Promise<Response<NotificationSettings>> {
  return axios.put(`${apiNaltaApp()}${version}/products/${id}/TogglePushNotifications`);
}

export function UpdateComponentDescription(id: number, value: string, version: Version = Version.V1): Promise<Response<InfraComponent>> {
  return axios.put(`${apiNaltaApp()}${version}/components/${id}/updatedescription?description=${value}`);
}

export function GetTrendHistory(
  organisationId: number,
  displayName: string,
  days: number,
  version: Version = Version.V2,
): Promise<Response<TrendGraph>> {
  return axios.get(`${apiNaltaApp()}${version}/organisations/${organisationId}/trends/${displayName}/?days=${days}`);
}

export function GetSubscriptionHistory(
  organisationId: number,
  displayName: string,
  days: number,
  version: Version = Version.V2,
): Promise<Response<SubscriptionGraph>> {
  return axios.get(`${apiNaltaApp()}${version}/organisations/${organisationId}/subscriptions/${displayName}/?days=${days}`);
}

export function GetQueueHistory(
  organisationId: number,
  queueName: string,
  days: number,
  version: Version = Version.V2,
): Promise<Response<QueueGraph>> {
  return axios.get(`${apiNaltaApp()}${version}/organisations/${organisationId}/queues/${queueName}/?days=${days}`);
}

export function UpdateTrendDescription(
  organisationId: number,
  subname: string,
  value: string,
  version: Version = Version.V1,
): Promise<Response<Trend>> {
  return axios.put(`${apiNaltaApp()}${version}/organisations/${organisationId}/trends/${subname}/updatedescription?description=${value}`);
}

export function UpdateSubscriptionDescription(
  organisationId: number,
  subname: string,
  value: string,
  version: Version = Version.V1,
): Promise<Response<Subscription>> {
  return axios.put(
    `${apiNaltaApp()}${version}/organisations/${organisationId}/subscriptions/${subname}/updatedescription?description=${value}`,
  );
}

export function UpdateQueueDescription(
  organisationId: number,
  queueName: string,
  value: string,
  version: Version = Version.V1,
): Promise<Response<Subscription>> {
  return axios.put(`${apiNaltaApp()}${version}/organisations/${organisationId}/queues/${queueName}/updatedescription?description=${value}`);
}

export function UpdateTrendBoundaries(
  organisationId: number,
  trendName: string,
  lowerBound: number,
  upperBound: number,
  healthClientName: string,
  key: string,
  version: Version = Version.V1,
): Promise<Response<Trend>> {
  const model = new UpdateBoundryTrend({ lowerBound, upperBound, healthClientName, key });
  return axios.put(`${apiNaltaApp()}${version}/organisations/${organisationId}/trends/${trendName}/setboundaries`, model);
}

export function UpdateSubscriptionThresholds(
  organisationId: number,
  subscriptionName: string,
  activeMessageThreshold: number,
  deadLetterMessageThreshold: number,
  scheduledMessageThreshold: number,
  healthClientName: string,
  version: Version = Version.V1,
): Promise<Response<Subscription>> {
  const model = new UpdateBoundriesThresholds({
    activeMessageThreshold,
    deadLetterMessageThreshold,
    scheduledMessageThreshold,
    healthClientName,
  });
  return axios.put(`${apiNaltaApp()}${version}/organisations/${organisationId}/subscriptions/${subscriptionName}/setthresholds`, model);
}

export function UpdateQueueThresholds(
  organisationId: number,
  queueName: string,
  activeMessageThreshold: number,
  deadLetterMessageThreshold: number,
  scheduledMessageThreshold: number,
  healthClientName: string,
  version: Version = Version.V1,
): Promise<Response<QueueModel>> {
  const model = new UpdateBoundriesThresholds({
    activeMessageThreshold,
    deadLetterMessageThreshold,
    scheduledMessageThreshold,
    healthClientName,
  });
  return axios.put(`${apiNaltaApp()}${version}/organisations/${organisationId}/queues/${queueName}/setthresholds`, model);
}

export function GetOrganisations(version: Version = Version.V1): Promise<Response<Organisation[]>> {
  return axios.get(`${apiNaltaApp()}${version}/Organisations`);
}

export function CreateServer(
  organisationId: number,
  name: string,
  healthMonitorName: string,
  version: Version = Version.V1,
): Promise<Response<Server>> {
  const model = { organisationId, name, healthMonitorName };
  return axios.post(`${apiNaltaApp()}${version}/Organisations/${organisationId}/Servers`, model);
}

export function CreateSubscription(
  organisationId: number,
  model: SubscriptionRequestModel,
  version: Version = Version.V1,
): Promise<Response<Subscription>> {
  return axios.post(`${apiNaltaApp()}${version}/Organisations/${organisationId}/Subscriptions`, model);
}

export function CreateQueue(
  organisationId: number,
  model: QueueRequestModel,
  version: Version = Version.V1,
): Promise<Response<QueueModel>> {
  return axios.post(`${apiNaltaApp()}${version}/Organisations/${organisationId}/Queues`, model);
}

export function CreateTrend(organisationId: number, model: TrendRequestModel, version: Version = Version.V1): Promise<Response<Trend>> {
  return axios.post(`${apiNaltaApp()}${version}/Organisations/${organisationId}/Trends`, model);
}

export function CreateComponent(model: ComponentRequestModel, version: Version = Version.V1): Promise<Response<Component>> {
  return axios.post(`${apiNaltaApp()}${version}/Components`, model);
}

export function CreateProduct(model: ProductRequestModel, version: Version = Version.V1): Promise<Response<Product>> {
  return axios.post(`${apiNaltaApp()}${version}/Products`, model);
}

export function GetComponentMonitors(version: Version = Version.V1): Promise<Response<ComponentMonitors>> {
  return axios.get(`${apiNaltaApp()}${version}/Products/ComponentMonitors`);
}

export function UpdateComponentMonitors(
  model: ComponentMonitorsResponseModel,
  version: Version = Version.V1,
): Promise<Response<ComponentMonitorsResponseModel>> {
  return axios.put(`${apiNaltaApp()}${version}/Products/ComponentMonitors`, model);
}

export function GetTeamMemberCreateData(version: Version = Version.V1): Promise<Response<TeamMemberCreateResponseModel>> {
  return axios.get(`${apiNaltaApp()}${version}/TeamMembers/CreateData`);
}

export function GetTeamMemberUpdateData(version: Version = Version.V1): Promise<Response<TeamMemberCreateResponseModel>> {
  return axios.get(`${apiNaltaApp()}${version}/TeamMembers/UpdateData`);
}

export function CreateTeamMember(model: TeamMemberCreateModel, version: Version = Version.V1): Promise<Response<User>> {
  return axios.post(`${apiNaltaApp()}${version}/TeamMembers`, model);
}

export function PutTeamMemberUpdate(
  id: number,
  role: string,
  description: string,
  isPublicProfile: boolean,
  version: Version = Version.V1,
): Promise<Response<TeamMemberCreateResponseModel>> {
  return axios.put(`${apiNaltaApp()}${version}/TeamMembers/Update`, {
    id: id,
    role: role,
    description: description,
    isPublicProfile: isPublicProfile,
  });
}

export function DeleteTeamMember(id: number, version: Version = Version.V1): Promise<Response<TeamMemberCreateResponseModel>> {
  return axios.put(`${apiNaltaApp()}${version}/TeamMembers/${id}/Delete`);
}

export function UpdateTrend(
  organisationId: number,
  trendId: number,
  model: TrendUpdateModel,
  version: Version = Version.V1,
): Promise<Response<TrendGraph>> {
  return axios.put(`${apiNaltaApp()}${version}/Organisations/${organisationId}/Trends/${trendId}`, model);
}

export function UpdateSubscription(
  organisationId: number,
  subscriptionId: number,
  model: SubscriptionUpdateModel,
  version: Version = Version.V1,
): Promise<Response<SubscriptionGraph>> {
  return axios.put(`${apiNaltaApp()}${version}/Organisations/${organisationId}/Subscriptions/${subscriptionId}`, model);
}

export function UpdateQueue(
  organisationId: number,
  queueId: number,
  model: QueueUpdateModel,
  version: Version = Version.V1,
): Promise<Response<QueueGraph>> {
  return axios.put(`${apiNaltaApp()}${version}/Organisations/${organisationId}/Queues/${queueId}`, model);
}
