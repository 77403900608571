import QueueModel from "./queueModel.class";
import Server from "./server.class";
import Subscription from "./subscription.class";
import Trend from "./trend.class";

export default class AvailableMonitors {
  trends: Trend[];
  servers: Server[];
  subscriptions: Subscription[];
  queues: QueueModel[];

  constructor(p?: AvailableMonitors) {
    this.trends = p?.trends ?? [];
    this.servers = p?.servers ?? [];
    this.subscriptions = p?.subscriptions ?? [];
    this.queues = p?.queues ?? [];
  }
}
