import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales: any = {
    en: () => require("@/locales/en.json"),
  };

  const messages: LocaleMessages = {};
  Object.keys(locales).forEach((key: string) => {
    messages[key] = locales[key]();
  });

  return messages;
}

export default new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});
