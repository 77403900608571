<template>
  <div class="containerBox">
    <LineChartGenerator class="box" :chart-options="this.chartOptions" :chart-data="this.data" />
    <label class="box stack-top">{{ this.boundariesLabel }}</label>
  </div>
</template>

<script>
  import { Line as LineChartGenerator } from "vue-chartjs/legacy";
  import { Chart, Filler } from "chart.js";
  Chart.register(Filler);

  import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement } from "chart.js";

  ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement);

  export default {
    name: "LineChart",
    components: {
      LineChartGenerator,
    },
    props: ["data", "chartOptions", "boundariesLabel"],
  };
</script>

<style scoped lang="scss">
  .containerBox {
    width: 100%;
    height: 400px;
    position: relative;
  }
  .box {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .stack-top {
    z-index: 9;
    justify-content: center;
    align-items: center;
    display: flex;
    pointer-events: none;
  }
</style>
