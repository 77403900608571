











































import Vue from "vue";
import CreateServerComponent from "@/components/Dashboard/CreateServerComponent.vue";
import CreateSubscriptionComponent from "@/components/Dashboard/CreateSubscriptionComponent.vue";
import CreateQueueComponent from "@/components/Dashboard/CreateQueueComponent.vue";
import CreateTrendComponent from "@/components/Dashboard/CreateTrendComponent.vue";
import CreateComponentComponent from "@/components/Dashboard/CreateComponentComponent.vue";
import CreateProductComponent from "@/components/Dashboard/CreateProductComponent.vue";

import Component from "vue-class-component";
import Organisation from "@/typing/webbeat/organisation.class";
import ToastrHelper from "@/helpers/toastHelper";
import { GetOrganisations, GetProducts } from "@/api/apiWebbeat";
import CreateTeamMemberComponent from "@/components/Dashboard/CreateTeamMemberComponent.vue";
import ProductBase from "@/typing/webbeat/productBase.class";

@Component({
  components: {
    CreateServerComponent,
    CreateSubscriptionComponent,
    CreateQueueComponent,
    CreateTrendComponent,
    CreateComponentComponent,
    CreateProductComponent,
    CreateTeamMemberComponent,
  },
})
export default class CreateFlow extends Vue {
  organisations: Organisation[] = [];
  products: ProductBase[] = [];

  async mounted(): Promise<void> {
    try {
      const result = await GetOrganisations();
      this.organisations = result.data.body;
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    try {
      const result = await GetProducts();
      this.products = result.data.body;
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }
}
