import { dayjsEx, updateAtFormat } from "@/utils/dayjs";

export default class AlertConfigHistoryResponseModel {
  id: number;
  updatedBy: string;
  updatedAt: Date;
  monitorType: string;
  trendKey: null | string;
  healthMonitorName: string;
  newUpperBound: number | null;
  oldUpperBound: number | null;
  newLowerBound: number | null;
  oldLowerBound: number | null;
  newActiveThreshold: number | null;
  oldActiveThreshold: number | null;
  newDeadletterThreshold: number | null;
  oldDeadletterThreshold: number | null;
  newScheduledThreshold: number | null;
  oldScheduledThreshold: number | null;
  updatedAtFormatted: string;

  constructor(p?: AlertConfigHistoryResponseModel) {
    this.id = p?.id ?? 0;
    this.updatedBy = p?.updatedBy ?? "";
    this.updatedAt = p?.updatedAt ?? new Date();
    this.monitorType = p?.monitorType ?? "";
    this.trendKey = p?.trendKey ?? "";
    this.healthMonitorName = p?.healthMonitorName ?? "";
    this.newUpperBound = p?.newUpperBound ?? null;
    this.oldUpperBound = p?.oldUpperBound ?? null;
    this.newLowerBound = p?.newLowerBound ?? null;
    this.oldLowerBound = p?.oldLowerBound ?? null;
    this.newActiveThreshold = p?.newActiveThreshold ?? null;
    this.oldActiveThreshold = p?.oldActiveThreshold ?? null;
    this.newDeadletterThreshold = p?.newDeadletterThreshold ?? null;
    this.oldDeadletterThreshold = p?.oldDeadletterThreshold ?? null;
    this.newScheduledThreshold = p?.newScheduledThreshold ?? null;
    this.oldScheduledThreshold = p?.oldScheduledThreshold ?? null;
    this.updatedAtFormatted = dayjsEx.utc(this.updatedAt).format(updateAtFormat);
  }
}
