import { setQueryParamsToUrl } from "@/utils/setQueryParams";
import Vue from "vue";

export default class BaseView extends Vue {
  /**
   * Sets the query parameters in the browser's history without reloading the page.
   *
   * @param {any} params - An object containing the query parameters to be set.
   * @returns {void}
   */
  setHistoryQueryParams(params: any): void {
    history.replaceState(null, "queryparams", setQueryParamsToUrl(location.origin + location.pathname, params));
  }

  constructor() {
    super();
  }
}
