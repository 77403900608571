
































import Component from "vue-class-component";
import LighthouseView from "@/views/lighthouse/LighthouseView";
import Loader from "@/components/Loader.vue";
import Build from "@/typing/lighthouse/build.class";
import { EventBus } from "@/utils/eventBus/eventBus";
import { EventBusEvent } from "@/utils/eventBus/eventBusEvent.enum";
import { getNavigationHistory } from "@/utils/eventBus/listnerFuncs/onNavigation";
import NavHistory from "@/typing/lighthouse/navHistory.class";

@Component({
  components: {
    Loader,
  },
})
export default class LibraryManager extends LighthouseView {
  tabIndex = -1;
  hasLoadBuilds = false;
  itemsList: Build[] = [];
  console = console;
  libraryTabActive = true;
  buildsTabActive = false;
  navHistory: NavHistory[] = [];
  isExpanded = false;

  isBuildsTabActive(): boolean {
    return this.$router.currentRoute.path.includes("/builds") || this.$router.currentRoute.path.includes("/builds/detail");
  }

  isLibraryTabActive(): boolean {
    return (
      this.$router.currentRoute.path.includes("/packages") ||
      this.$router.currentRoute.path.includes("/package") ||
      this.$router.currentRoute.path.includes("/package/detail")
    );
  }

  changeTab(tabIndex: number): void {
    if (tabIndex === 0) {
      this._gotoLibraryManager();
    } else {
      this._gotoBuilds();
    }
  }

  updateTabs(): void {
    this.libraryTabActive = this.isLibraryTabActive();
    this.buildsTabActive = this.isBuildsTabActive();
  }

  updateCrumb(): void {
    this.navHistory = getNavigationHistory();
  }

  private _gotoLibraryManager(): void {
    if (this.$router.currentRoute.path === "/packages") {
      return;
    }
    this.$router.push({ path: "/packages" });
  }

  private _gotoBuilds(): void {
    if (this.$router.currentRoute.path === "/builds") {
      return;
    }
    this.$router.push({ path: "/builds" });
  }

  async mounted(): Promise<void> {
    EventBus.$on(EventBusEvent.Navigated, () => {
      this.updateCrumb();
      this.updateTabs();
      this.isExpanded = false;
    });
    this.updateTabs();
  }
}
