

































































































































import Component from "vue-class-component";
import LighthouseView from "@/views/lighthouse/LighthouseView";
import Package from "@/typing/lighthouse/package.class";
import Loader from "@/components/Loader.vue";
import ToastrHelper from "@/helpers/toastHelper";
import { getPackageStatusIconClass } from "@/helpers/iconHelper";
import storeTypes from "@/store/storeTypes";
import { EventBus } from "@/utils/eventBus/eventBus";
import { EventBusEvent } from "@/utils/eventBus/eventBusEvent.enum";
import Modal from "@/components/Modal.vue";
import PackageRequest from "@/typing/lighthouse/packageRequest.class";
import { PostRequestPackage } from "@/api/apiLightHouse";

@Component({
  components: {
    Loader,
    Modal,
  },
})
export default class PackagesView extends LighthouseView {
  get items(): Package[] {
    let items = [...this.$store.state.packages];

    if (items.length === 0) {
      return [];
    }

    return items.filter((x) => x.isLatestVersion);
  }

  get sortOptions(): any {
    return this.fields
      .filter((f) => f.sortable)
      .map((f) => {
        return { text: f.label, value: f.key };
      });
  }
  isLoading = false;
  fields: any[] = [
    {
      key: "name",
      label: this.$t("PackageName") as string,
      sortable: true,
    },
    {
      key: "version",
      label: this.$t("Version") as string,
      sortable: true,
    },
    {
      key: "approval",
      label: this.$t("Approval") as string,
      sortable: false,
    },
    {
      key: "authors",
      label: this.$t("Authors") as string,
      sortable: true,
    },
  ];
  totalRows = 1;
  currentPage = 1;
  pageSize = 25;
  pageOptions: number[] = [25, 50, 100, 200, 500];
  filter: any = this.$route.query.q ?? null;
  filterOn = this.$route.query.f ?? "";
  outlined = true;
  typeToSearch: string = this.$t("TypeToSearch") as string;
  filterLabel: string = this.$t("Filter") as string;
  filterOnLabel: string = this.$t("FilterOn") as string;
  perPage: string = this.$t("PerPage") as string;
  showModal = false;
  packageRequest = new PackageRequest();

  getPackageStatusIconClass = getPackageStatusIconClass;

  async mounted(): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(storeTypes.actions.GET_PACKAGES);
    } catch (e) {
      console.error(e);
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.totalRows = this.items.length;
    this.isLoading = false;
    EventBus.$emit(EventBusEvent.Navigated, {
      name: "Packages",
      clear: true,
    });
  }

  onFiltered(filteredItems: any) {
    // Update Query Parameters in URL with seach values (for history back)
    this.setHistoryQueryParams({ q: this.filter, f: this.filterOn });

    // Update navigation history
    EventBus.$emit(EventBusEvent.Navigated, {
      name: "Packages",
      path: location.pathname + location.search,
      clear: true,
      //replace: true,
    });

    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  async goToPackageView(item: Package) {
    this.$router.push({ path: `/package?packageKey=${item.key}` });
  }

  showForm() {
    this.showModal = true;
  }

  closeForm() {
    this.showModal = false;
    this.packageRequest = new PackageRequest();
  }

  async requestPackage() {
    try {
      this.isLoading = true;
      await PostRequestPackage(this.packageRequest);
      ToastrHelper.successToastr(this.$t("SuccesPackageRequest") as string, this);
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    } finally {
      this.closeForm();
      this.isLoading = false;
    }
  }
}
