import i18n from "@/i18n";
import BaseProp from "./baseProp.class";

export default class Version extends BaseProp {
  get tableApprovedValue() {
    return this.approved ? (i18n.t("Yes") as string) : (i18n.t("No") as string);
  }

  version: string;
  isLatestVersion: boolean;
  approved: boolean;
  approvedBy: string;
  riskScore: number;

  // FE only
  packageName?: string;

  constructor(p?: Version, packageName?: string) {
    super(p);

    this.approvedBy = p?.approvedBy ?? "";
    this.version = p?.version ?? "";
    this.isLatestVersion = p?.isLatestVersion ?? false;
    this.approved = p?.approved ?? false;
    this.riskScore = p?.riskScore ?? 0;
    this.isDeprecated = p?.isDeprecated ?? false;
    this.isVulnerable = p?.isVulnerable ?? false;

    this.packageName = packageName ?? undefined;
  }
}
