import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const dayjsEx = dayjs;
dayjsEx.extend(utc);
export const timeFormat = "HH:mm";
export const dateFormat = "DD/MM/YYYY";
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;
export const fromatDateTime = "MMM DD, HH:mm";
export const updateAtFormat = "MMM DD, YYYY : HH:mm";
