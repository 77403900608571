export default class Organisation {
  id: number;
  name: string;
  logo: string;

  constructor(o?: Organisation) {
    this.id = o?.id ?? 0;
    this.name = o?.name ?? "";
    this.logo = o?.logo ?? "";
  }
}
