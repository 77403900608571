import Organisation from "./organisation.class";
import Product from "./product.class";
import User from "./user.class";

export default class TeamMemberCreateResponseModel {
  organisations: Organisation[];
  products: Product[];
  users: User[];
  constructor(t?: TeamMemberCreateResponseModel) {
    this.organisations = t?.organisations ?? [];
    this.products = t?.products ?? [];
    this.users = t?.users ?? [];
  }
}
