import { GetBuildPackagesByKey, GetBuilds, GetPackage, GetPackages } from "@/api/apiLightHouse";
import Build from "@/typing/lighthouse/build.class";
import Package from "@/typing/lighthouse/package.class";
import PackageDetail from "@/typing/lighthouse/packageDetails.class";
import { IEnvironment, IState } from "@/typing/state.interface";
import InfraComponent from "@/typing/webbeat/component.class";
import Product from "@/typing/webbeat/product.class";
import Subscription from "@/typing/webbeat/subscription.class";
import Trend from "@/typing/webbeat/trend.class";
import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import storeTypes from "./storeTypes";
import Version from "@/typing/lighthouse/version.class";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    environment: {} as any,
    webbeatProducts: [] as any,
    packages: [] as any,
    packagesDetail: [] as any,
    packageName: "" as any,
    packageSummary: "" as any,
    packageLatestVersion: "" as any,
    packageAuthors: "" as any,
    build: undefined as unknown as Build,
    builds: [] as Build[],
    versions: [] as any,
  },
  mutations: {
    SET_ENVIRONMENT: (state: IState, payload: IEnvironment) => (state.environment = payload),
    SET_WEBBEAT_PRODUCTS: (state: IState, payload: Product[]) => (state.webbeatProducts = payload),
    SET_LIGHTHOUSE_PACKAGES: (state: IState, payload: Package[]) => (state.packages = payload),
    SET_LIGHTHOUSE_PACKAGE: (state: IState, payload: PackageDetail[]) => (state.packagesDetail = payload),
    SET_LIGHTHOUSE_PACKAGE_NAME: (state: IState, payload: string) => (state.packageName = payload),
    SET_LIGHTHOUSE_PACKAGE_SUMMARY: (state: IState, payload: string) => (state.packageSummary = payload),
    SET_LIGHTHOUSE_PACKAGE_LATEST_VERSION: (state: IState, payload: string) => (state.packageLatestVersion = payload),
    SET_LIGHTHOUSE_PACKAGE_AUTHORS: (state: IState, payload: string) => (state.packageAuthors = payload),
    SET_LIGHTHOUSE_BUILDS: (state: IState, payload: Build[]) => (state.builds = payload),
    SET_LIGHTHOUSE_BUILD: (state: IState, payload: Build) => (state.build = payload),
    SET_LIGHTHOUSE_PACKAGE_VERSIONS: (state: IState, payload: Version[]) => (state.versions = payload),
    f: (state: IState, payload: Build) => (state.build = payload),
  },
  actions: {
    UPDATE_PRODUCT: (c: ActionContext<IState, IState>, p: Product) => {
      const products = [...c.state.webbeatProducts];
      const productIndex = products.findIndex((x) => x.id === p.id);
      if (productIndex === -1) {
        return;
      }

      products.splice(productIndex, 1, p);
      c.commit("SET_WEBBEAT_PRODUCTS", products);
    },

    GET_BUILDS: async (c: ActionContext<IState, IState>) => {
      try {
        const results = await GetBuilds();
        const items: Build[] = [];
        results.data.body.forEach((item: Build) => {
          items.push(new Build(item));
        });
        c.commit(storeTypes.mutations.SET_LIGHTHOUSE_BUILDS, items);
      } catch (e) {
        throw new Error(e as any);
      }
    },

    GET_BUILDDETAIL: async (c: ActionContext<IState, IState>, buildKey: string) => {
      try {
        const result: any = await GetBuildPackagesByKey(buildKey);
        const build = new Build(result.data.body);

        c.commit(storeTypes.mutations.SET_LIGHTHOUSE_BUILD, build);
      } catch (e) {
        throw new Error(e as any);
      }
    },

    GET_PACKAGES: async (c: ActionContext<IState, IState>) => {
      try {
        const results = await GetPackages();
        const items: Package[] = [];
        results.data.body
          ?.sort((a: Package, b: Package) => {
            const sortResult = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            if (sortResult === 0) {
              return b.version.toLowerCase().localeCompare(a.version.toLowerCase());
            }
            return sortResult;
          })
          .forEach((item: Package) => {
            items.push(new Package(item));
          });
        c.commit("SET_LIGHTHOUSE_PACKAGES", items);
      } catch (e) {
        throw new Error(e as any);
      }
    },

    GET_PACKAGEDETAIL: async (c: ActionContext<IState, IState>, packageKey: string) => {
      try {
        const items: PackageDetail[] = [];
        const result: any = await GetPackage(packageKey);

        const name: string = result.data.body.name;
        const summary: string = result.data.body.description;
        const authors: string = result.data.body.authors;
        const versions: Version[] = result.data.body.versions;
        const latestVersion: string = result.data.body.versions[0].version;

        c.commit("SET_LIGHTHOUSE_PACKAGE_NAME", name);
        c.commit("SET_LIGHTHOUSE_PACKAGE_SUMMARY", summary);
        c.commit("SET_LIGHTHOUSE_PACKAGE_LATEST_VERSION", latestVersion);
        c.commit("SET_LIGHTHOUSE_PACKAGE_AUTHORS", authors);
        c.commit("SET_LIGHTHOUSE_PACKAGE_VERSIONS", versions);

        result.data.body.versions.forEach((item: PackageDetail) => {
          items.push(new PackageDetail(item));
        });
        c.commit("SET_LIGHTHOUSE_PACKAGE", items);
      } catch (e) {
        throw new Error(e as any);
      }
    },

    UPDATE_LIGHTHOUSE_PACKAGES: (c: ActionContext<IState, IState>, p: Package) => {
      const pds = [...c.state.packages];
      const pdIndex = pds.findIndex((x) => x.key === p.key);
      if (pdIndex === -1) {
        return;
      }

      pds.splice(pdIndex, 1, p);
      c.commit(storeTypes.mutations.SET_LIGHTHOUSE_PACKAGES, pds);
    },

    UPDATE_LIGHTHOUSE_PACKAGE: (c: ActionContext<IState, IState>, p: PackageDetail) => {
      const pds = [...c.state.packagesDetail];
      const pdIndex = pds.findIndex((x) => x.key === p.key);
      if (pdIndex === -1) {
        return;
      }

      pds.splice(pdIndex, 1, p);
      c.commit(storeTypes.mutations.SET_LIGHTHOUSE_PACKAGE, pds);
    },

    UPDATE_COMPONENT: (c: ActionContext<IState, IState>, actionData: InfraComponent) => {
      const products = [...c.state.webbeatProducts];
      const productIndex = products.findIndex((x) => x.id == actionData.productId);
      if (productIndex === -1) return;
      const componentIndex = products[productIndex].components.findIndex((x) => x.id == actionData.id);
      if (componentIndex === -1) return;
      products[productIndex].components.splice(componentIndex, 1, actionData);
      c.commit("SET_WEBBEAT_PRODUCTS", products);
    },

    UPDATE_COMPONENT_TREND: (c: ActionContext<IState, IState>, actionData: { model: Trend; productId: number }) => {
      const products = [...c.state.webbeatProducts];
      const productIndex = products.findIndex((x) => x.id == actionData.productId);

      if (productIndex === -1) return;

      const componentIndex = products[productIndex].components.findIndex((x) => x.id == actionData.model.componentId);
      if (componentIndex === -1) return;

      const trendIndex = products[productIndex].components[componentIndex].trends.findIndex((x) => x.id == actionData.model.id);
      if (trendIndex === -1) return;

      products[productIndex].components[componentIndex].trends.splice(trendIndex, 1, actionData.model);
      c.commit("SET_WEBBEAT_PRODUCTS", products);
    },

    UPDATE_COMPONENT_SUBSCRIPTION: (c: ActionContext<IState, IState>, actionData: { model: Subscription; productId: number }) => {
      const products = [...c.state.webbeatProducts];
      const productIndex = products.findIndex((x) => x.id == actionData.productId);
      if (productIndex === -1) return;

      const componentIndex = products[productIndex].components.findIndex((x) => x.id == actionData.model.componentId);
      if (componentIndex === -1) return;

      const subscriptionIndex = products[productIndex].components[componentIndex].trends.findIndex((x) => x.id == actionData.model.id);
      if (subscriptionIndex === -1) return;

      products[productIndex].components[componentIndex].subscriptions.splice(subscriptionIndex, 1, actionData.model);
      c.commit("SET_WEBBEAT_PRODUCTS", products);
    },
  },
  modules: {},
});
