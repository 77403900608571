














































































import Vue from "vue";
import Component from "vue-class-component";
import { CreateSubscription } from "@/api/apiWebbeat";
import ToastrHelper from "@/helpers/toastHelper";
import SubscriptionRequestModel from "@/typing/webbeat/subscriptionRequestModel.class";
import Modal from "@/components/Modal.vue";

@Component({
  props: {
    organisations: { type: [], required: true },
  },
  components: {
    Modal,
  },
})
export default class CreateServerComponent extends Vue {
  topicSubscriptionName = "";
  displayName = "";
  healthMonitor = "";
  description = "";
  activeMessageTreshold = 0;
  deadLetterMessageTreshold = 0;
  scheduledMessageTreshold = 0;
  addHealthAlertConfig = false;
  repeatInterval = {};
  selected = "";
  showModal = false;

  async createSubscription() {
    try {
      const model = new SubscriptionRequestModel();
      model.topicSubscriptionName = this.topicSubscriptionName;
      model.displayName = this.displayName;
      model.description = this.description;
      model.healthMonitorName = this.healthMonitor;
      model.activeMessageTreshold = this.activeMessageTreshold;
      model.deadLetterMessageTreshold = this.deadLetterMessageTreshold;
      model.scheduledMessageTreshold = this.scheduledMessageTreshold;
      model.addHealthAlertConfig = this.addHealthAlertConfig;
      model.organisationId = parseInt(this.selected);
      const result = await CreateSubscription(model.organisationId, model);

      if (result.data.hasErrors) {
        ToastrHelper.successToastr(result.data.errors[0].message, this);
      } else {
        ToastrHelper.successToastr("Subscription is succesvol aangemaakt", this);
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.toggleModal();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
