export default class ComponentRequestModel {
  name: string;
  description: string;
  productId: number;

  constructor(s?: ComponentRequestModel) {
    this.productId = s?.productId ?? 0;
    this.name = s?.name ?? "";
    this.description = s?.description ?? "";
  }
}
