


















































import Vue from "vue";
import Component from "vue-class-component";
import { CreateTrend } from "@/api/apiWebbeat";
import ToastrHelper from "@/helpers/toastHelper";
import TrendRequestModel from "@/typing/webbeat/trendRequestModel.class";
import Modal from "@/components/Modal.vue";

@Component({
  props: {
    organisations: { type: [], required: true },
  },
  components: {
    Modal,
  },
})
export default class CreateTrendComponent extends Vue {
  key = "";
  healthMonitor = "";
  description = "";
  displayName = "";
  lowerBound = 0;
  upperBound = 0;
  addHealthAlertConfig = false;
  selected = "";
  showModal = false;
  selectedType = "";
  trendTypes = [
    { id: "0", name: "TrendComparison" },
    { id: "1", name: "TrendDifference" },
  ];

  async createTrend() {
    try {
      const model = new TrendRequestModel();
      model.key = this.key;
      model.description = this.description;
      model.displayName = this.displayName;
      model.healthMonitorName = this.healthMonitor;
      model.lowerBound = this.lowerBound;
      model.upperBound = this.upperBound;
      model.addHealthAlertConfig = this.addHealthAlertConfig;
      model.organisationId = parseInt(this.selected);
      if (model.addHealthAlertConfig) {
        model.trendType = parseInt(this.selectedType);
      }

      const result = await CreateTrend(model.organisationId, model);

      if (result.data.hasErrors) {
        ToastrHelper.successToastr(result.data.errors[0].message, this);
      } else {
        ToastrHelper.successToastr("Trend is succesvol aangemaakt", this);
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.toggleModal();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
