import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Auth0Plugin, isAuth0Loaded } from "./utils/auth";
import { initInterceptors } from "./utils/axiosInterceptors";
import LoadEnvironment from "./utils/environment/loadEnvironment";
import { initGlobalListeners } from "./utils/eventBus/eventBus";
import { setVueInstance } from "./utils/vueInstance";
import "./scss/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import i18n from "./i18n";
import Multiselect from "vue-multiselect";
import { QueryParamWatcher } from "./utils/watchQueryParams";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("vue-multiselect", Multiselect);

async function initApp(): Promise<void> {
  const env = await LoadEnvironment();

  Vue.use(Auth0Plugin, {
    domain: env.auth0?.domain,
    clientId: env.auth0?.clientId,
    audience: env.auth0?.audience,
  });

  await isAuth0Loaded();

  const appInstance = new Vue({ router, store, i18n, render: (h) => h(App) });

  setVueInstance(appInstance);

  appInstance.$store.commit("SET_ENVIRONMENT", env);

  initInterceptors();
  initGlobalListeners();

  (appInstance as any).$auth.checkAuthentication();

  appInstance.$mount("#app");

  new QueryParamWatcher().startWatch();
}

initApp();
