import { decodeJWT } from "@/utils/auth";
import { vueInstance } from "@/utils/vueInstance";

export const localStorageEntries = { entryUrl: "entryUrl" };

export default async function onAuthed(): Promise<void> {
  await decodeJWT();

  let desiredRoute = localStorage.getItem(localStorageEntries.entryUrl);
  localStorage.removeItem(localStorageEntries.entryUrl);

  if (desiredRoute?.includes("auth") || desiredRoute === "/") {
    desiredRoute = "/home";
  }

  vueInstance().$router.push(desiredRoute ?? "/home");
}
