import ProductComponentMonitorsResponseModel from "./productComponentMonitorsResponseModel.class";
import AvailableMonitors from "./availableMonitors.class";

export default class ComponentMonitors {
  productComponentMonitors: ProductComponentMonitorsResponseModel[];
  availableMonitors: AvailableMonitors;

  constructor(p?: ComponentMonitors) {
    this.productComponentMonitors = [];
    if (p && p.productComponentMonitors) {
      this.productComponentMonitors = p.productComponentMonitors.map((item) => new ProductComponentMonitorsResponseModel(item));
    }
    this.availableMonitors = p?.availableMonitors ?? new AvailableMonitors();
  }
}
