import Vue from "vue";
import { Auth0Client, Auth0ClientOptions, GetTokenSilentlyOptions } from "@auth0/auth0-spa-js";
import { EventBus } from "../eventBus/eventBus";
import { EventBusEvent } from "../eventBus/eventBusEvent.enum";
import jwt_decode from "jwt-decode";

/** Define a default action to perform after authentication */
// eslint-disable-next-line
const DEFAULT_REDIRECT_CALLBACK = (appState?: any) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

let instance: any;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;
export const localStorageEntries = { entryUrl: "entryUrl" };

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = `${window.location.origin}/auth/callback`,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue<any>({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        decodedJWT: {},
      };
    },
    methods: {
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        try {
          await (this.auth0Client as any).handleRedirectCallback();

          this.user = await (this.auth0Client as Auth0Client).getUser();
          this.isAuthenticated = true;
          setTimeout(() => {
            EventBus.$emit(EventBusEvent.Authed);
          }, 500);
        } catch (e) {
          this.error = e;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect() {
        return (this.auth0Client as any).loginWithRedirect({
          redirectUri: `${window.location.origin}/auth/callback`,
        });
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o?: GetTokenSilentlyOptions) {
        return (this.auth0Client as any).getTokenSilently(o ?? {});
      },
      async getUserId(): Promise<any> {
        return (await this.getIdTokenClaims()).sub;
      },
      /** Logs the user out and removes their session on the authorization server */
      logout() {
        return (this.auth0Client as Auth0Client).logout();
      },
      async instanceCreatedCallback() {
        this.auth0Client.checkSession();
        this.isAuthenticated = await this.auth0Client.isAuthenticated();

        if (this.isAuthenticated) {
          this.user = await this.auth0Client.getUser();
          setTimeout(() => {
            EventBus.$emit(EventBusEvent.Authed);
          }, 500);
        }
      },
      checkAuthentication() {
        if (!this.isAuthenticated) {
          localStorage.setItem(localStorageEntries.entryUrl, location.pathname + location.search);
          this.auth0Client.loginWithRedirect();
        }
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      (this.auth0Client as any) = await new Auth0Client({
        domain: options.domain,
        clientId: options.clientId,
        authorizationParams: {
          audience: options.audience,
          redirect_uri: redirectUri,
        },
      });

      try {
        // If the user is returning to the app after authentication..
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          // handle the redirect and retrieve tokens
          const { appState } = await (this.auth0Client as any).handleRedirectCallback();
          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        await this.instanceCreatedCallback();
        this.loading = false;
      }
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue: any, options: Auth0ClientOptions) {
    Vue.prototype.$auth = useAuth0(options);
  },
};

export function isAuth0Loaded(): Promise<any> {
  const poll = (resolve: any) => {
    if (getInstance() && !getInstance().loading) {
      resolve();
    } else {
      setTimeout(() => poll(resolve), 400);
    }
  };

  return new Promise(poll);
}

export async function hasPermission(permission: string): Promise<boolean> {
  try {
    return getInstance().decodedJWT.permissions.includes(permission);
  } catch (e) {
    return false;
  }
}

export function hasPermissionSync(permission: string): boolean {
  try {
    return getInstance().decodedJWT.permissions.includes(permission);
  } catch (e) {
    return false;
  }
}

export async function decodeJWT(): Promise<void> {
  try {
    const instance = getInstance();
    const token = await instance.getTokenSilently();
    const decoded = jwt_decode(token) as any;
    instance.decodedJWT = decoded;
  } catch (e) {
    console.error(e);
  }
}
