export default class TrendRequestModel {
  key: string;
  displayName: string;
  description: string;
  healthMonitorName: string;
  lowerBound: number;
  upperBound: number;
  organisationId: number;
  addHealthAlertConfig: boolean;
  trendType: number | undefined;

  constructor(t?: TrendRequestModel) {
    this.key = t?.key ?? "";
    this.displayName = t?.displayName ?? "";
    this.description = t?.description ?? "";
    this.healthMonitorName = t?.healthMonitorName ?? "";
    this.lowerBound = t?.lowerBound ?? 0;
    this.upperBound = t?.upperBound ?? 0;
    this.organisationId = t?.organisationId ?? 0;
    this.addHealthAlertConfig = t?.addHealthAlertConfig ?? false;
    this.trendType = t?.trendType ?? undefined;
  }
}
