import SubscriptionHistory from "../subscriptionHistory.class";
import BoundsGraphDataset from "./graphBase.class";

export default class SubscriptionGraph extends BoundsGraphDataset {
  history: SubscriptionHistory;
  topicSubscriptionName: string;

  constructor(t?: SubscriptionGraph) {
    super(t);
    this.topicSubscriptionName = t?.topicSubscriptionName ?? "";
    this.history = t?.history ?? new SubscriptionHistory();
  }
}
