


















import Vue from "vue";
import Component from "vue-class-component";
@Component({
  props: {
    lowerBound: { type: Number, default: null },
    currentBound: { type: Number, required: true },
    upperBound: { type: Number, required: true },
    percentage: { type: Number, required: true },
    displayName: { type: String, required: true },
    serviceType: { type: String, required: true },
    isDisrupted: { type: Boolean, required: true },
    serviceToShow: { type: String, default: null },
    type: { type: String, required: true },
    updateDate: { type: String, required: true },
  },
})
export default class Monitor extends Vue {
  datediff(dateString: string): boolean {
    const date = new Date(dateString);
    const Difference_In_Time = Date.now() - date.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 7) return true;
    return false;
  }

  get IsDisruptedLow(): boolean {
    if (this.$props.lowerBound === null) return false;
    return this.$props.currentBound < this.$props.lowerBound;
  }

  get IsDisruptedHigh(): boolean {
    return this.$props.currentBound > this.$props.upperBound;
  }

  get ServiceImage(): string {
    if (this.$props.serviceType === "Threshold") {
      return this.$props.isDisrupted ? require("@/assets/trend-offline.svg") : require("@/assets/trend-online.svg");
    } else {
      return this.$props.isDisrupted ? require("@/assets/subscription-offline.svg") : require("@/assets/subscription-online.svg");
    }
  }

  get StatusType(): string {
    return !this.$props.isDisrupted ? "Online" : "Disrupted";
  }
  get getDetail(): string {
    if (this.$props.serviceType === "Threshold") {
      return this.$props.currentBound + ` Msg/day for (${this.$props.type})`;
    } else {
      if (this.$props.serviceToShow === "active") {
        return this.$props.currentBound + ` In line ${this.$t("ActiveMessage")} for (${this.$props.type})`;
      }

      if (this.$props.serviceToShow === "DLQ") {
        return this.$props.currentBound + ` In line (DLQ) for (${this.$props.type})`;
      }

      if (this.$props.serviceToShow === "scheduled") {
        return this.$props.currentBound + ` In line (scheduled) for (${this.$props.type})`;
      }

      return this.$props.currentBound + ` In line ${this.$t("ActiveMessage")} for (${this.$props.type})`;
    }
  }
}
