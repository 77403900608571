import { dayjsEx, updateAtFormat } from "@/utils/dayjs";
import Notification from "./notification.class";
import Server from "./server.class";
import Subscription from "./subscription.class";
import Trend from "./trend.class";
import QueueModel from "./queueModel.class";

export default class InfraComponent {
  id: number;
  name: string;
  description: string;
  isDisrupted: boolean;
  updatedAt: Date;
  productId: number;
  notifications: Notification[];
  trends: Trend[];
  servers: Server[];
  subscriptions: Subscription[];
  queues: QueueModel[];
  statusType: string;
  totalItemsOnline: string;
  totalItemsLabel: string;
  updatedAtFormatted: string;
  hasServers: boolean;

  constructor(c?: InfraComponent) {
    this.id = c?.id ?? 0;
    this.name = c?.name ?? "";
    this.description = c?.description ?? "";
    this.isDisrupted = c?.isDisrupted ?? false;
    this.updatedAt = c?.updatedAt ?? new Date();
    this.productId = c?.productId ?? 0;
    this.notifications = c?.notifications ?? [];
    this.trends = c?.trends ?? [];
    this.servers = c?.servers ?? [];
    this.subscriptions = c?.subscriptions ?? [];
    this.queues = c?.queues ?? [];
    this.statusType = !this.isDisrupted ? "Online" : "Disrupted";
    this.totalItemsLabel = (this.servers.length + this.trends.length + this.subscriptions.length + this.queues.length).toString();
    this.totalItemsOnline = (
      this.servers.filter((x) => x.isOnline).length +
      this.trends.filter((x) => !x.isDisrupted).length +
      this.subscriptions.filter((x) => !x.isDisrupted).length +
      this.queues.filter((x) => !x.isDisrupted).length
    ).toString();
    this.updatedAtFormatted = dayjsEx.utc(this.updatedAt).format(updateAtFormat);
    this.hasServers = this.servers.length > 0;
  }
}
