import { dayjsEx, updateAtFormat } from "@/utils/dayjs";

export default class Trend {
  id: number;
  key: string;
  displayName: string;
  description: string;
  healthMonitorName: string;
  isDisrupted: boolean;
  lowerBound: number;
  upperBound: number;
  lastKnownValue: number;
  updatedAt: string;
  componentId: number;
  organisationId: number;
  updatedAtFormatted: string;
  order: number;

  constructor(t?: Trend) {
    this.id = t?.id ?? 0;
    this.key = t?.key ?? "";
    this.displayName = t?.displayName ?? "";
    this.description = t?.description ?? "";
    this.order = t?.order ?? 0;
    this.healthMonitorName = t?.healthMonitorName ?? "";
    this.isDisrupted = t?.isDisrupted ?? false;
    this.lowerBound = t?.lowerBound ?? 0;
    this.upperBound = t?.upperBound ?? 0;
    this.lastKnownValue = t?.lastKnownValue ?? 0;
    this.updatedAt = t?.updatedAt ?? "";
    this.componentId = t?.componentId ?? 0;
    this.organisationId = t?.organisationId ?? 0;
    this.updatedAtFormatted = dayjsEx.utc(this.updatedAt).format(updateAtFormat);
    this.order = t?.order ?? 0;
  }
}
