export default class SubscriptionUpdateModel {
  displayName: string;
  description: string;
  activeMessageThreshold: number;
  deadLetterMessageThreshold: number;
  scheduledMessageThreshold: number;
  order: number;

  constructor(t?: Partial<SubscriptionUpdateModel>) {
    this.order = t?.order ?? 0;
    this.displayName = t?.displayName ?? "";
    this.description = t?.description ?? "";
    this.activeMessageThreshold = t?.activeMessageThreshold ?? 0;
    this.deadLetterMessageThreshold = t?.deadLetterMessageThreshold ?? 0;
    this.scheduledMessageThreshold = t?.scheduledMessageThreshold ?? 0;
  }
}
