


































import Vue from "vue";
import Component from "vue-class-component";
import { CreateServer } from "@/api/apiWebbeat";
import ToastrHelper from "@/helpers/toastHelper";
import Modal from "@/components/Modal.vue";

@Component({
  props: {
    organisations: { type: [], required: true },
  },
  components: {
    Modal,
  },
})
export default class CreateServerComponent extends Vue {
  name = "";
  healtMonitor = "";
  selected = "";
  showModal = false;

  async createServer() {
    try {
      const result = await CreateServer(parseInt(this.selected), this.name, this.healtMonitor);
      if (result.data.hasErrors) {
        ToastrHelper.successToastr(result.data.errors[0].message, this);
      } else {
        ToastrHelper.successToastr("Server is succesvol aangemaakt", this);
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.toggleModal();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
