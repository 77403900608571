





































































































import Vue from "vue";

import Component from "vue-class-component";
import ToastrHelper from "@/helpers/toastHelper";
import draggable from "vuedraggable";
import { GetComponentMonitors, GetOrganisations, UpdateComponentMonitors } from "@/api/apiWebbeat";
import ComponentMonitors from "@/typing/webbeat/componentMonitors.class";
import Picker from "@/components/Picker/Picker.vue";
import PickerMultiple from "@/components/Picker/PickerMultiple.vue";
import ComponentMonitorsResponseModel from "@/typing/webbeat/componentMonitorsResponseModel.class";
import Trend from "@/typing/webbeat/trend.class";
import Subscription from "@/typing/webbeat/subscription.class";
import Server from "@/typing/webbeat/server.class";
import QueueModel from "@/typing/webbeat/queueModel.class";
import Loader from "@/components/Loader.vue";
import Organisation from "@/typing/webbeat/organisation.class";

@Component({
  components: {
    Picker,
    PickerMultiple,
    Loader,
    draggable,
  },
})
export default class EditComponentMonitors extends Vue {
  componentMonitors: ComponentMonitors = new ComponentMonitors();
  selectedProduct = null;
  selectedComponent = null;
  pickerValue = [];
  pickerValueTrends: Array<Trend> = [];
  pickerValueServers: Array<Server> = [];
  pickerValueSubscriptions: Array<Subscription> = [];
  pickerValueQueues: Array<QueueModel> = [];
  components: ComponentMonitorsResponseModel[] = [];
  component: ComponentMonitorsResponseModel = new ComponentMonitorsResponseModel();
  addedTrend: Array<Trend> = [];
  addedSubscription: Array<Subscription> = [];
  addedServers: Array<Server> = [];
  addedQueues: Array<QueueModel> = [];
  isLoading = false;
  organisationId = 0;
  organisations: Organisation[] = [];
  combinedList: (Trend | Subscription | QueueModel)[] = [];
  goOrderList = false;

  async mounted(): Promise<void> {
    try {
      const result = await GetOrganisations();
      this.organisations = result.data.body;
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    try {
      this.isLoading = true;
      const result = await GetComponentMonitors();
      this.componentMonitors = new ComponentMonitors(result.data.body);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  combineAndSortLists() {
    // Combine the lists
    const combinedList = [...this.addedTrend, ...this.addedSubscription, ...this.addedQueues];

    // Sort the combined list based on the order property
    combinedList.sort((a, b) => a.order - b.order);
    return combinedList;
  }

  changed() {
    this.combinedList = this.combineAndSortLists();
  }

  async saveMonitor() {
    if (!this.selectedComponent) return;
    const model = this.components.find((x) => x.id == this.selectedComponent);
    if (!model) return;
    const newModel = new ComponentMonitorsResponseModel(model);
    const trends: Trend[] = [];
    const subscriptions: Subscription[] = [];
    const queues: QueueModel[] = [];

    this.combinedList.forEach((item, index) => {
      item.order = index + 1; // Update the order property

      if (this.isTrendLike(item)) {
        trends.push(item as Trend);
      } else if (this.isSubscriptionLike(item)) {
        subscriptions.push(item as Subscription);
      } else if (this.isQueueModelLike(item)) {
        queues.push(item as QueueModel);
      }
    });

    newModel.trends = trends;
    newModel.servers = [...this.addedServers];
    newModel.subscriptions = subscriptions;
    newModel.queues = queues;

    try {
      this.isLoading = true;
      await UpdateComponentMonitors(newModel);
      const result = await GetComponentMonitors();
      this.componentMonitors = new ComponentMonitors(result.data.body);
      this.selectedProduct = null;
      this.selectedComponent = null;
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  isTrendLike(obj: any) {
    return (
      "id" in obj &&
      "key" in obj &&
      "displayName" in obj &&
      "description" in obj &&
      "healthMonitorName" in obj &&
      "isDisrupted" in obj &&
      "lowerBound" in obj &&
      "upperBound" in obj &&
      "lastKnownValue" in obj &&
      "updatedAt" in obj &&
      "order" in obj
    );
  }
  // Define a function to check if an object is similar to a Subscription instance
  isSubscriptionLike(obj: any) {
    return (
      "id" in obj &&
      "topicSubscriptionName" in obj &&
      "displayName" in obj &&
      "description" in obj &&
      "healthMonitorName" in obj &&
      "isDisrupted" in obj &&
      "activeMessageTreshold" in obj &&
      "deadLetterMessageTreshold" in obj &&
      "scheduledMessageTreshold" in obj &&
      "activeMessageCount" in obj &&
      "deadLetterMessageCount" in obj &&
      "scheduledMessageCount" in obj &&
      "updatedAt" in obj &&
      "serviceToShow" in obj &&
      "order" in obj &&
      "organisationId" in obj
    );
  }

  // Define a function to check if an object is similar to a QueueModel instance
  isQueueModelLike(obj: any) {
    return (
      "id" in obj &&
      "queueName" in obj &&
      "displayName" in obj &&
      "description" in obj &&
      "healthMonitorName" in obj &&
      "isDisrupted" in obj &&
      "activeMessageTreshold" in obj &&
      "deadLetterMessageTreshold" in obj &&
      "scheduledMessageTreshold" in obj &&
      "activeMessageCount" in obj &&
      "deadLetterMessageCount" in obj &&
      "scheduledMessageCount" in obj &&
      "updatedAt" in obj &&
      "serviceToShow" in obj &&
      "order" in obj &&
      "organisationId" in obj
    );
  }

  getComponentList(productComponentMonitorId: number): ComponentMonitorsResponseModel[] {
    const model = this.componentMonitors.productComponentMonitors.find((x) => x.id == productComponentMonitorId);
    this.organisationId = model?.organisationId ?? 0;
    this.components = model?.components ?? [];

    return this.components;
  }

  clearLists() {
    this.addedTrend.splice(0, this.addedTrend.length);
    this.addedSubscription.splice(0, this.addedSubscription.length);
    this.addedServers.splice(0, this.addedServers.length);
    this.addedQueues.splice(0, this.addedQueues.length);

    this.pickerValueTrends.splice(0, this.pickerValueTrends.length);
    this.pickerValueSubscriptions.splice(0, this.pickerValueSubscriptions.length);
    this.pickerValueServers.splice(0, this.pickerValueServers.length);
    this.pickerValueQueues.splice(0, this.pickerValueQueues.length);
  }

  generateListsForPicker(componentId: number): void {
    this.clearLists();
    const model = this.components.find((x) => x.id == componentId);
    this.component = model ?? new ComponentMonitorsResponseModel();
    this.component.trends.forEach((item) => {
      this.addedTrend.push(item);
    });

    this.component.subscriptions.forEach((item) => {
      this.addedSubscription.push(item);
    });

    this.component.servers.forEach((item) => {
      this.addedServers.push(item);
    });

    this.component.queues.forEach((item) => {
      this.addedQueues.push(item);
    });

    this.componentMonitors.availableMonitors.trends.forEach((item) => {
      if (item.organisationId === this.organisationId) {
        if (!this.addedTrend.includes(item)) {
          this.pickerValueTrends.push(item);
        }
      }
    });

    this.componentMonitors.availableMonitors.subscriptions.forEach((item) => {
      if (item.organisationId === this.organisationId) {
        if (!this.addedSubscription.includes(item)) {
          this.pickerValueSubscriptions.push(item);
        }
      }
    });

    this.componentMonitors.availableMonitors.servers.forEach((item) => {
      if (item.organisationId === this.organisationId) {
        if (!this.addedServers.includes(item)) {
          this.pickerValueServers.push(item);
        }
      }
    });

    this.componentMonitors.availableMonitors.queues.forEach((item) => {
      if (item.organisationId === this.organisationId) {
        if (!this.addedQueues.includes(item)) {
          this.pickerValueQueues.push(item);
        }
      }
    });
    this.combinedList = this.combineAndSortLists();
  }
}
