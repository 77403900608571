import TrendHistory from "../trendHistory.class";
import { GraphDataset } from "./graphBase.class";

export default class TrendGraph extends GraphDataset {
  key: string;
  lowerBound: number;
  upperBound: number;
  lastKnownValue: number;

  history: TrendHistory;

  constructor(t?: TrendGraph) {
    super(t);
    this.key = t?.key ?? "";
    this.lowerBound = t?.lowerBound ?? 0;
    this.upperBound = t?.upperBound ?? 0;
    this.lastKnownValue = t?.lastKnownValue ?? 0;
    this.history = new TrendHistory(t?.history);
  }
}