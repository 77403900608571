









































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    possibilities: Array,
    value: Array,
    labelKey: String,
    valueKey: String,
  },
})
export default class PickerMultiple extends Vue {
  clickedItem = null;
  timeOfSelection: Date | null = null;

  get unassigned(): any[] {
    const selected = this.$props.value.map((y: any) => y[this.$props.valueKey]);
    return this.$props.possibilities.filter((x: any) => !selected.includes(x[this.$props.valueKey]));
  }

  itemIsSelected(item: any): boolean {
    if (!this.clickedItem) return false;

    return item[this.$props.valueKey] === (this.clickedItem as any)[this.$props.valueKey];
  }

  setSelected(item: any, isSelected: boolean): void {
    const selectionTime = new Date();
    const clickedItem = this.clickedItem as any;

    if (clickedItem && clickedItem[this.$props.valueKey] === item[this.$props.valueKey]) {
      const maxDoubleClickTime = 750; // milliseconds
      const isDoubleClick = selectionTime.valueOf() - (this.timeOfSelection as Date).valueOf() <= maxDoubleClickTime;

      if (isDoubleClick) {
        this.selectOrDeselect(item, isSelected);
        return;
      } else {
        this.timeOfSelection = selectionTime;
      }
    } else {
      this.clickedItem = item;
      this.timeOfSelection = selectionTime;
    }
  }

  selectOrDeselect(item: any | null, deSelect: boolean): void {
    if (!item) return;

    const currentSelection = [...this.$props.value];
    if (deSelect) {
      const index = currentSelection.findIndex((x) => x[this.$props.valueKey] === item[this.$props.valueKey]);
      if (index === -1) return;
      currentSelection.splice(index, 1);
    } else {
      currentSelection.push(item);
    }
    this.clickedItem = null;
    this.$emit("update", currentSelection);
    this.$emit("changed");
  }
}
