import ComponentMonitorsResponseModel from "./componentMonitorsResponseModel.class";

export default class ProductComponentMonitorsResponseModel {
  id: number;
  name: string;
  organisationId: number;
  organisationName: string;
  components: ComponentMonitorsResponseModel[];
  fullName: string;

  constructor(p?: ProductComponentMonitorsResponseModel) {
    this.id = p?.id ?? 0;
    this.name = p?.name ?? "";
    this.organisationId = p?.organisationId ?? 0;
    this.organisationName = p?.organisationName ?? "";
    this.components = p?.components ?? [];
    this.fullName = `${p?.name ?? ""} (${p?.organisationName ?? ""})`;
  }
}
