import { Permission } from "@/typing/permissions.enum";
import { getInstance, hasPermission } from ".";

export default function authGuard(to: any, from: any, next: any) {
  const authService = getInstance();

  const fn = () => {
    // If the user is not authenticated, go to login page
    if (!authService.isAuthenticated) {
      getInstance().loginWithRedirect();
      return;
    }

    let requiredPermission = null;
    switch (to.name) {
      case "Builds":
      case "BuildDetail":
        requiredPermission = Permission.buildsList;
        break;
      case "EditTeamMember":
      case "EditComponentMonitors":
      case "CreateFlow":
      case "Dashboard":
        requiredPermission = Permission.adminappCreate;
        break;
      case "GraphDetail":
      case "ComponentDetail":
      case "ProductDetail":
      case "Webbeat":
        requiredPermission = Permission.adminappRead;
        break;
    }

    //If the page requires permission and the user does not have permission, go to home page
    if (requiredPermission && !hasPermission(requiredPermission)) {
      next({ name: "Home" });
      return;
    }

    next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading: boolean) => {
    if (loading === false) {
      return fn();
    }
  });
}
