import Organisation from "./organisation.class";

export default class ProductBase {
  id: number;
  name: string;
  generatedLogo: string;
  organisationId: number;
  isDisrupted: boolean;
  isEmailEnabled: boolean;
  isPushEnabled: boolean;
  organisation: Organisation;
  colorCode: string;
  serviceLevelAgreement: string;
  snoozedTill: Date;
  avatarName: string;

  constructor(p?: ProductBase) {
    this.id = p?.id ?? 0;
    this.name = p?.name ?? "";
    this.generatedLogo = p?.generatedLogo ?? "";
    this.isDisrupted = p?.isDisrupted ?? false;
    this.isEmailEnabled = p?.isEmailEnabled ?? false;
    this.isPushEnabled = p?.isPushEnabled ?? false;
    this.organisation = p?.organisation ?? new Organisation();

    this.snoozedTill = p?.snoozedTill ?? new Date();
    this.colorCode = p?.colorCode ?? "";
    this.serviceLevelAgreement = p?.serviceLevelAgreement ?? "";
    this.organisationId = p?.organisationId ?? 0;
    this.avatarName = this.name.substring(0, 2);
  }
}
