import Axios, { AxiosError } from "axios";
import { getInstance } from "./auth";

export function initInterceptors(): void {
  Axios.interceptors.request.use(
    async function (config) {
      const token = await getInstance().getTokenSilently();

      if (token) {
        (config.headers as any) = {
          Authorization: `Bearer ${token}`,
        };
      }

      return config;
    },
    function (error: AxiosError) {
      return Promise.reject(error);
    },
  );

  // Response interceptor
  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
}
