const storeTypes = {
  mutations: {
    SET_ENVIRONMENT: "SET_ENVIRONMENT",
    SET_WEBBEAT_PRODUCTS: "SET_WEBBEAT_PRODUCTS",
    SET_LIGHTHOUSE_PACKAGES: "SET_LIGHTHOUSE_PACKAGES",
    SET_LIGHTHOUSE_PACKAGE: "SET_LIGHTHOUSE_PACKAGE",
    SET_LIGHTHOUSE_BUILDS: "SET_LIGHTHOUSE_BUILDS",
    SET_LIGHTHOUSE_BUILD: "SET_LIGHTHOUSE_BUILD",
  },
  actions: {
    UPDATE_PRODUCT: "UPDATE_PRODUCT",
    UPDATE_COMPONENT: "UPDATE_COMPONENT",
    UPDATE_COMPONENT_TREND: "UPDATE_COMPONENT_TREND",
    UPDATE_COMPONENT_SUBSCRIPTION: "UPDATE_COMPONENT_SUBSCRIPTION",
    UPDATE_LIGHTHOUSE_PACKAGE: "UPDATE_LIGHTHOUSE_PACKAGE",
    UPDATE_LIGHTHOUSE_PACKAGES: "UPDATE_LIGHTHOUSE_PACKAGES",
    GET_PACKAGES: "GET_PACKAGES",
    GET_PACKAGEDETAIL: "GET_PACKAGEDETAIL",
    GET_BUILDS: "GET_BUILDS",
    GET_BUILDDETAIL: "GET_BUILDDETAIL",
  },
};

export default storeTypes;
