








import Vue from "vue";
import Component from "vue-class-component";
@Component({
  props: {
    displayName: { type: String, required: true },
    isOnline: { type: Boolean, required: true },
  },
})
export default class Monitor extends Vue {
  get ServiceImage(): string {
    return this.$props.isOnline ? require("@/assets/server-online.svg") : require("@/assets/server-offline.svg");
  }
}
