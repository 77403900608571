import QueueValue from "./queueValue.class";

export default class QueueHistory {
  beginDate: Date;
  queueName: string;
  healthClientName: string;
  values: QueueValue[];

  constructor(t?: QueueHistory) {
    this.beginDate = t?.beginDate ?? new Date();
    this.queueName = t?.queueName ?? "";
    this.healthClientName = t?.healthClientName ?? "";
    this.values = t?.values ?? [];
  }
}
