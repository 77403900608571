import { IEnvironment } from "@/typing/state.interface";
import axios from "axios";

export default async function LoadEnvironment(): Promise<IEnvironment> {
  const envConfig = await axios.get(`${window.location.origin}/environments/env.json`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return await envConfig.data;
}
