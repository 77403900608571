import { dayjsEx, updateAtFormat } from "@/utils/dayjs";

export default class QueueModel {
  id: number;
  displayName: string;
  topicSubscriptionName: string;
  queueName: string;
  activeMessageTreshold: number;
  deadLetterMessageTreshold: number;
  scheduledMessageTreshold: number;
  activeMessageCount: number;
  deadLetterMessageCount: number;
  scheduledMessageCount: number;
  componentId: number;
  description: string;
  isDisrupted: boolean;
  updatedAt: string;
  serviceToShow: string;
  healthClientName: string;
  healthMonitorName: string;
  organisationId: number;
  updatedAtFormatted: string;
  order: number;

  constructor(s?: QueueModel) {
    this.id = s?.id ?? 0;
    this.topicSubscriptionName = s?.topicSubscriptionName ?? "";
    this.displayName = s?.displayName ?? "";
    this.queueName = s?.queueName ?? "";
    this.description = s?.description ?? "";
    this.healthMonitorName = s?.healthMonitorName ?? "";
    this.healthClientName = s?.healthClientName ?? "";
    this.isDisrupted = s?.isDisrupted ?? false;
    this.activeMessageTreshold = s?.activeMessageTreshold ?? 0;
    this.deadLetterMessageTreshold = s?.deadLetterMessageTreshold ?? 0;
    this.scheduledMessageTreshold = s?.scheduledMessageTreshold ?? 0;
    this.activeMessageCount = s?.activeMessageCount ?? 0;
    this.deadLetterMessageCount = s?.deadLetterMessageCount ?? 0;
    this.scheduledMessageCount = s?.scheduledMessageCount ?? 0;
    this.updatedAt = s?.updatedAt ?? "";
    this.serviceToShow = s?.serviceToShow ?? "";
    this.componentId = s?.componentId ?? 0;
    this.organisationId = s?.organisationId ?? 0;
    this.updatedAtFormatted = dayjsEx.utc(this.updatedAt).format(updateAtFormat);
    this.order = s?.order ?? 0;
  }
}
