import Vue from "vue";
import { EventBusEvent } from "./eventBusEvent.enum";
import onAuthed from "./listnerFuncs/onAuthed";
import onNavigation from "./listnerFuncs/onNavigation";

export const EventBus = new Vue();

export function initGlobalListeners(): void {
  EventBus.$on(EventBusEvent.Authed, () => onAuthed());
  EventBus.$on(EventBusEvent.Navigated, (nav: any) => onNavigation(nav));
}
