export default class ProductRequestModel {
  name: string;
  generatedLogo: string;
  organisationId: number;
  colorCode: string;

  constructor(s?: ProductRequestModel) {
    this.organisationId = s?.organisationId ?? 0;
    this.name = s?.name ?? "";
    this.generatedLogo = s?.generatedLogo ?? "";
    this.colorCode = s?.colorCode ?? "";
  }
}
