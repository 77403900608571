

















































































































































import Component from "vue-class-component";
import LighthouseView from "@/views/lighthouse/LighthouseView";
import { GetBuildByBranchAndVersion } from "@/api/apiLightHouse";
import PackageDetail from "@/typing/lighthouse/packageDetails.class";
import Loader from "@/components/Loader.vue";
import Build from "@/typing/lighthouse/build.class";
import ToastrHelper from "@/helpers/toastHelper";
import storeTypes from "@/store/storeTypes";
import getQueryParams from "@/utils/getQueryParams";
import { EventBus } from "@/utils/eventBus/eventBus";
import { EventBusEvent } from "@/utils/eventBus/eventBusEvent.enum";
import Version from "@/typing/lighthouse/version.class";

@Component({
  components: {
    Loader,
  },
})
export default class BuildDetail extends LighthouseView {
  get sortOptions(): any {
    return this.fields
      .filter((f) => f.sortable)
      .map((f) => {
        return { text: f.label, value: f.key };
      });
  }

  get isVulnerable(): boolean {
    return this.build.riskScore >= 100;
  }

  get itemKey(): string {
    return getQueryParams("key");
  }

  get build(): Build {
    return this.$store.state.build;
  }

  get items(): Version[] {
    return this.$store.state.build.packages.flatMap((x) => x.versions);
  }

  isLoading = false;
  fields: any[] = [
    {
      key: "packageName",
      label: this.$t("PackageName") as string,
      sortable: true,
    },
    { key: "version", label: this.$t("PackageVersion") as string },
    {
      key: "isLatestVersion",
      label: this.$t("LatestVersion") as string,
      formatter: (value: any) => {
        return value ? (this.$t("Yes") as string) : (this.$t("No") as string);
      },
    },
    {
      key: "tableApprovedValue",
      label: "Approved",
    },
    {
      key: "riskScore",
      label: "Risk Score",
      formatter: (value: any) => {
        return value ? (value as string) : "-";
      },
    },
    {
      key: "approvedBy",
      label: this.$t("ApprovalBy") as string,
      sortable: true,
    },
  ];
  totalRows = 1;
  currentPage = 1;
  pageSize = 25;
  pageOptions: number[] = [25, 50, 100, 200, 500];
  filter: any = this.$route.query.q ?? null;
  filterOn = this.$route.query.f ?? "";
  outlined = true;
  itemModel: Build | null = null;
  typeToSearch: string = this.$t("TypeToSearch") as string;
  filterLabel: string = this.$t("Filter") as string;
  filterOnLabel: string = this.$t("FilterOn") as string;
  moreOptions = false;
  builds: Build[] = [];
  selected = "";
  options?: [];

  async mounted(): Promise<void> {
    this.loadData(this.itemKey);
  }

  onFiltered(filteredItems: any): void {
    // Update Query Parameters in URL with seach values (for history back)
    this.setHistoryQueryParams({ q: this.filter, f: this.filterOn });
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  rowClass(item: PackageDetail) {
    if (!item.lastVersion) {
      return;
    }

    if (this.$store.state.versions[0].isVulnerable) {
      return "table-warning";
    }

    if (item.lastVersion.approvedBy == "" && item.lastVersion.approved == false) {
      return "";
    }

    if (!item.lastVersion.approved) {
      return "table-danger";
    }
    return "table-success";
  }

  buttonVariant(item: PackageDetail) {
    if (!item.lastVersion) {
      return "danger";
    }

    if (item.lastVersion.approved) {
      return "danger";
    }

    return "success";
  }

  buttonTitle(item: PackageDetail) {
    if (!item.lastVersion) {
      return this.$t("ApprovePackage") as string;
    }

    return item.lastVersion.approved ? (this.$t("DeclinePackage") as string) : (this.$t("ApprovePackage") as string);
  }

  async goToPackageDetail(item: PackageDetail) {
    this.$router.push({ path: `/package/detail?packageKey=${item.key}` });
  }

  async changeOption() {
    try {
      this.$router.push({ path: `/builds/detail?key=${this.selected}` });
      this.loadData(this.selected);
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  async loadData(buildKey: string) {
    try {
      this.isLoading = true;

      await this.$store.dispatch(storeTypes.actions.GET_BUILDDETAIL, buildKey);

      const resultBuilds = await GetBuildByBranchAndVersion(this.build.name, this.build.branch);

      this.moreOptions = resultBuilds.data.body.length > 1 ? true : false;
      this.selected = buildKey;

      this.builds = resultBuilds.data.body.map((x) => new Build(x));

      this.totalRows = this.items.length;
      this.isLoading = false;

      EventBus.$emit(EventBusEvent.Navigated, {
        name: this.build.name + " " + this.build.buildNumber,
      });
    } catch (e) {
      console.error(e);
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
  }

  getIconStatus(item: PackageDetail) {
    if (!item.lastVersion) {
      return;
    }

    if (item.lastVersion.approvedBy == "" && item.lastVersion.approved == false) {
      return "dash-circle";
    }
    if (!item.lastVersion.approved) {
      return "x-circle";
    }
    return "check-circle";
  }
}
