


































import Vue from "vue";
import Component from "vue-class-component";
import { CreateComponent } from "@/api/apiWebbeat";
import ToastrHelper from "@/helpers/toastHelper";
import ComponentRequestModel from "@/typing/webbeat/componentRequestModel.class";
import Modal from "@/components/Modal.vue";

@Component({
  props: {
    products: { type: [], required: true },
  },
  components: {
    Modal,
  },
})
export default class CreateComponentComponent extends Vue {
  name = "";
  description = "";
  lowerBound = 0;
  upperBound = 0;
  flatten = false;
  selected = "";
  showModal = false;

  async createComponent() {
    try {
      const model = new ComponentRequestModel();
      model.name = this.name;
      model.description = this.description;
      model.productId = parseInt(this.selected);
      const result = await CreateComponent(model);
      if (result.data.hasErrors) {
        ToastrHelper.successToastr(result.data.errors[0].message, this);
      } else {
        ToastrHelper.successToastr("Component is succesvol aangemaakt", this);
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.toggleModal();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
