export default class BaseProp {
  key: string;
  isDeprecated: boolean;
  isVulnerable: boolean;

  constructor(p?: BaseProp) {
    this.key = p?.key ?? "";
    this.isDeprecated = p?.isDeprecated ?? false;
    this.isVulnerable = p?.isVulnerable ?? false;
  }
}
