


























import Component from "vue-class-component";
import Vue from "vue";
import PickerMultiple from "@/components/Picker/PickerMultiple.vue";
import Picker from "@/components/Picker/Picker.vue";

@Component({
  components: {
    PickerMultiple,
    Picker,
  },
})
export default class ComponentPlayGround extends Vue {
  pickerObjects = [
    { id: 1, name: "Test" },
    { id: 2, name: "Beproeving" },
    { id: 3, name: "Experiment" },
    { id: 4, name: "Probeersel" },
  ];

  pickerValue = [];
  singlePickerValue = null;
}
