








import Component from "vue-class-component";
import Vue from "vue";
import Header from "./components/Header.vue";
import DevToolbar from "./components/DevToolbar.vue";
import getEnv from "./utils/environment/getEnvironment";
import { IEnvironment } from "@/typing/state.interface";

@Component({
  components: {
    Header,
    DevToolbar,
  },
})
export default class App extends Vue {
  get env(): IEnvironment {
    return getEnv();
  }
}
