import { IState } from "@/typing/state.interface";
import { globalVueInstanceVariableName } from "./customWindow";

export const vueInstance = (): Vue => {
  const instance = (window as any)[globalVueInstanceVariableName];

  if (!instance) {
    throw new Error("The Vue instance is either not created yet, or not globally available.");
  }

  return instance;
};

export const setVueInstance = (instance: Vue): void => {
  (window as any)[globalVueInstanceVariableName] = instance;
};

export function getState(): IState {
  return vueInstance().$store.state;
}
