import BaseProp from "./baseProp.class";

export default class Package extends BaseProp {
  name: string;
  version: string;
  isLatestVersion: boolean | null;
  authors: string;
  approved: boolean | null;
  riskScore: number;

  constructor(p?: Package) {
    super(p);
    this.name = p?.name ?? "";
    this.version = p?.version ?? "";
    this.isLatestVersion = p?.isLatestVersion ?? null;
    this.authors = p?.authors ?? "";
    this.approved = p?.approved ?? null;
    this.riskScore = p?.riskScore ?? 0;
    this.isDeprecated = p?.isDeprecated ?? false;
    this.isVulnerable = p?.isVulnerable ?? false;
  }
}
