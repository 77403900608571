export default class TrendUpdateModel {
  displayName: string;
  description: string;
  healthMonitorName: string;
  lowerBound: number;
  upperBound: number;
  order: number;

  constructor(t?: TrendUpdateModel) {
    this.order = t?.order ?? 0;
    this.displayName = t?.displayName ?? "";
    this.description = t?.description ?? "";
    this.healthMonitorName = t?.healthMonitorName ?? "";
    this.lowerBound = t?.lowerBound ?? 0;
    this.upperBound = t?.upperBound ?? 0;
  }
}
