




































import Vue from "vue";
import Component from "vue-class-component";
import { CreateProduct } from "@/api/apiWebbeat";
import ToastrHelper from "@/helpers/toastHelper";
import ProductRequestModel from "@/typing/webbeat/productRequestModel.class";
import Modal from "@/components/Modal.vue";

@Component({
  props: {
    organisations: { type: [], required: true },
  },
  components: {
    Modal,
  },
})
export default class CreateProductComponent extends Vue {
  name = "";
  generatedLogo = "";
  color = "";
  selected = "";
  showModal = false;

  async createProduct() {
    try {
      const model = new ProductRequestModel();
      model.name = this.name;
      if (this.color === "") {
        this.color = "#000000";
      }
      model.colorCode = this.color;
      model.organisationId = parseInt(this.selected);

      const result = await CreateProduct(model);

      if (result.data.hasErrors) {
        ToastrHelper.successToastr(result.data.errors[0].message, this);
      } else {
        ToastrHelper.successToastr("Product is succesvol aangemaakt", this);
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.toggleModal();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
