export function setQueryParamsToUrl(url: string, params: any) {
  // Get existing QS
  const searchParams = new URLSearchParams(window.location.search);

  // Iterate over the object properties and update or remove them accordingly
  for (const key in params) {
    if (params[key] && params[key].length) {
      searchParams.set(key, params[key]);
    } else {
      searchParams.delete(key);
    }
  }

  // Construct the URL with the new QS
  const paramsString = searchParams.toString();
  const updatedUrl = `${url}${paramsString.length ? `?${paramsString}` : ""}`;

  return updatedUrl;
}
