export default class SubscriptionRequestModel {
  topicSubscriptionName: string;
  displayName: string;
  healthMonitorName: string;
  activeMessageTreshold: number;
  deadLetterMessageTreshold: number;
  scheduledMessageTreshold: number;
  organisationId: number;
  addHealthAlertConfig: boolean;
  description: string;
  constructor(s?: SubscriptionRequestModel) {
    this.organisationId = s?.organisationId ?? 0;
    this.topicSubscriptionName = s?.topicSubscriptionName ?? "";
    this.displayName = s?.displayName ?? "";
    this.healthMonitorName = s?.healthMonitorName ?? "";
    this.description = s?.description ?? "";
    this.activeMessageTreshold = s?.activeMessageTreshold ?? 0;
    this.deadLetterMessageTreshold = s?.deadLetterMessageTreshold ?? 0;
    this.scheduledMessageTreshold = s?.scheduledMessageTreshold ?? 0;
    this.addHealthAlertConfig = s?.addHealthAlertConfig ?? false;
  }
}
