































import Vue from "vue";
import Component from "vue-class-component";
import { hasPermissionSync } from "@/utils/auth";
import { Permission } from "@/typing/permissions.enum";

@Component({
  components: {},
})
export default class Home extends Vue {
  hasBuildsListPermission(): boolean {
    return hasPermissionSync(Permission.buildsList);
  }

  isAdminApp(): boolean {
    return hasPermissionSync(Permission.adminappRead);
  }

  isAdminappCreate(): boolean {
    return hasPermissionSync(Permission.adminappCreate);
  }
}
