


















import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    possibilities: Array,
    value: String,
    labelKey: String,
    valueKey: String,
  },
})
export default class Picker extends Vue {
  itemIsSelected(item: any): boolean {
    return item[this.$props.valueKey].toString() === (this.$props.value ?? "");
  }

  select(item: any | null): void {
    if (!item) return;

    this.$emit("input", (item[this.$props.valueKey] ?? "").toString());
  }
}
