import { dayjsEx, fromatDateTime } from "@/utils/dayjs";

export default class ChartDataModel {
  date: string;
  value: number;
  receivedAtDate: Date;
  formattedDate: string;
  constructor(c?: ChartDataModel) {
    this.date = c?.date ?? "";
    this.value = c?.value ?? 0;
    this.receivedAtDate = c?.receivedAtDate ?? new Date();
    this.formattedDate = c?.formattedDate ?? "";
  }
}
