import InfraComponent from "./component.class";
import TeamMember from "./teamMember.class";
import ProductBase from "./productBase.class";
import QueueModel from "./queueModel.class";
import Server from "./server.class";
import Trend from "./trend.class";
import Subscription from "./subscription.class";
import AlertConfigHistoryResponseModel from "./alertConfigHistoryResponseModel.class";

export default class Product extends ProductBase {
  teamMembers: TeamMember[];
  components: InfraComponent[];
  queues: QueueModel[];
  servers: Server[];
  trends: Trend[];
  subscriptions: Subscription[];
  hasServers: boolean;
  alertConfigHistories: AlertConfigHistoryResponseModel[] | null;

  constructor(p?: Product) {
    super(p);
    this.teamMembers = p?.teamMembers ?? [];

    this.components = p?.components ?? [];
    this.queues = p?.queues ?? [];
    this.servers = p?.servers ?? [];
    this.trends = p?.trends ?? [];
    this.subscriptions = p?.subscriptions ?? [];
    this.hasServers = this.servers.length > 0;
    this.alertConfigHistories = p?.alertConfigHistories ?? null;
  }
}
