














































































import Vue from "vue";
import Component from "vue-class-component";
import { CreateQueue } from "@/api/apiWebbeat";
import ToastrHelper from "@/helpers/toastHelper";
import QueueRequestModel from "@/typing/webbeat/queueRequestModel.class";
import Modal from "@/components/Modal.vue";

@Component({
  props: {
    organisations: { type: [], required: true },
  },
  components: {
    Modal,
  },
})
export default class CreateQueueComponent extends Vue {
  queueName = "";
  healthMonitor = "";
  description = "";
  displayName = "";

  activeMessageTreshold = 0;
  deadLetterMessageTreshold = 0;
  scheduledMessageTreshold = 0;
  addHealthAlertConfig = false;
  sizePercentageTreshold = 0;
  repeatInterval = {};
  selected = "";
  showModal = false;

  async createQueue() {
    try {
      const model = new QueueRequestModel();
      model.queueName = this.queueName;
      model.description = this.description;
      model.displayName = this.displayName;
      model.healthMonitorName = this.healthMonitor;
      model.activeMessageTreshold = this.activeMessageTreshold;
      model.deadLetterMessageTreshold = this.deadLetterMessageTreshold;
      model.scheduledMessageTreshold = this.scheduledMessageTreshold;
      model.addHealthAlertConfig = this.addHealthAlertConfig;
      model.sizePercentageTreshold = this.sizePercentageTreshold;
      model.organisationId = parseInt(this.selected);
      const result = await CreateQueue(model.organisationId, model);

      if (result.data.hasErrors) {
        ToastrHelper.successToastr(result.data.errors[0].message, this);
      } else {
        ToastrHelper.successToastr("Queue is succesvol aangemaakt", this);
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.toggleModal();
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
