import AlertConfigHistoryResponseModel from "../alertConfigHistoryResponseModel.class";

export class GraphDataset {
  id: number;
  displayName: string;
  description: string | undefined;
  healthMonitorName: string;
  isDisrupted: boolean;

  updatedAt: Date;
  organisationId: number;
  alertConfigHistory: AlertConfigHistoryResponseModel[] | null;

  constructor(dataset?: GraphDataset) {
    this.id = dataset?.id ?? 0;
    this.updatedAt = dataset?.updatedAt ?? new Date();
    this.displayName = dataset?.displayName ?? "";
    this.healthMonitorName = dataset?.healthMonitorName ?? "";

    this.isDisrupted = dataset?.isDisrupted ?? false;
    this.organisationId = dataset?.organisationId ?? 0;
    this.alertConfigHistory = dataset?.alertConfigHistory ?? null;
  }
}

export default class BoundsGraphDataset extends GraphDataset {
  activeMessageCount: number;
  deadLetterMessageCount: number;
  scheduledMessageCount: number;
  activeMessageTreshold: number;
  deadLetterMessageTreshold: number;
  scheduledMessageTreshold: number;
  

  constructor(t?: BoundsGraphDataset) {
    super(t);
    this.activeMessageCount = t?.activeMessageCount ?? 0;
    this.deadLetterMessageCount = t?.deadLetterMessageCount ?? 0;
    this.scheduledMessageCount = t?.scheduledMessageCount ?? 0;
    this.activeMessageTreshold = t?.activeMessageTreshold ?? 0;
    this.deadLetterMessageTreshold = t?.deadLetterMessageTreshold ?? 0;
    this.scheduledMessageTreshold = t?.scheduledMessageTreshold ?? 0;
  }
}

