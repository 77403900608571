import getEnv from "@/utils/environment/getEnvironment";
import axios, { AxiosResponse } from "axios";
import { INaltaAPIResponse } from "@/typing/webbeat/nltAPIResponse.interface";
import Package from "@/typing/lighthouse/package.class";
import PackageDetail from "@/typing/lighthouse/packageDetails.class";
import Build from "@/typing/lighthouse/build.class";
import PackageRequest from "@/typing/lighthouse/packageRequest.class";

function apiBaseUrl(): string {
  return getEnv().apiBaseUrl ?? "";
}

type Response<T> = AxiosResponse<INaltaAPIResponse<T>>;

export function GetPackages(): Promise<Response<Package[]>> {
  return axios.get(`${apiBaseUrl()}/api/v1/Packages`);
}

export function GetPackage(key: string): Promise<Response<PackageDetail[]>> {
  return axios.get(`${apiBaseUrl()}/api/v1/Packages/${key}`);
}

export function GetPackageDetails(body = {}): Promise<Response<PackageDetail[]>> {
  return axios.get(`${apiBaseUrl()}/api/v1/Packages`, body);
}

export function PutPackageApprove(key: string, riskScore: string): Promise<Response<PackageDetail>> {
  return axios.put(`${apiBaseUrl()}/api/v1/Packages/${key}/Approve`, {
    RiskScore: riskScore,
  });
}

export function PutPackageDecline(key: string, reason: string): Promise<Response<PackageDetail>> {
  return axios.put(`${apiBaseUrl()}/api/v1/Packages/${key}/Decline`, {
    reason: reason,
  });
}

export function GetPackageBuild(key: string): Promise<Response<Build[]>> {
  return axios.get(`${apiBaseUrl()}/api/v1/Packages/${key}/builds`);
}

export function GetBuilds(): Promise<Response<Build[]>> {
  return axios.get(`${apiBaseUrl()}/api/v1/Build`);
}

export function GetBuildPackagesByKey(key: string): Promise<Response<Build>> {
  return axios.get(`${apiBaseUrl()}/api/v1/Build/${key}`);
}

export function PostRequestPackage(model: PackageRequest): Promise<Response<PackageRequest>> {
  return axios.post(`${apiBaseUrl()}/api/v1/Packages/Request`, model);
}

export function GetBuildByBranchAndVersion(name: string, branch: string): Promise<Response<Build[]>> {
  return axios.get(`${apiBaseUrl()}/api/v1/Build?branch=${branch}&buildName=${name}`);
}
