import { EventBus } from "./eventBus/eventBus";
import { EventBusEvent } from "./eventBus/eventBusEvent.enum";

export class QueryParamWatcher {
  get queryString(): string {
    return window.location.search;
  }

  get path(): string {
    return window.location.pathname;
  }

  private _lastKnownQueryString = "";
  private _lastKnownPath = "";
  private _watchInterval: number | null = null;

  constructor() {
    this._lastKnownQueryString = this.queryString;
    this._lastKnownPath = this.path;
  }

  startWatch(): void {
    this._watchInterval = setInterval(() => {
      const changedOnSamePage = this._lastKnownPath === this.path && this._lastKnownQueryString !== this.queryString;

      if (changedOnSamePage) {
        this._handleQueryStringChange();
      }

      this._lastKnownQueryString = this.queryString;
      this._lastKnownPath = this.path;
    }, 100);
  }

  stopWatch(): void {
    if (!this._watchInterval) return;

    clearInterval(this._watchInterval);
    this._watchInterval = null;
  }

  private _handleQueryStringChange(): void {
    EventBus.$emit(EventBusEvent.QueryStringChanged);
  }
}
