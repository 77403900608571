import QueueModel from "./queueModel.class";
import Server from "./server.class";
import Subscription from "./subscription.class";
import Trend from "./trend.class";

export default class ComponentMonitorsResponseModel {
  id: number;
  name: string;
  productId: number;
  trends: Trend[];
  servers: Server[];
  subscriptions: Subscription[];
  queues: QueueModel[];

  constructor(p?: ComponentMonitorsResponseModel) {
    this.id = p?.id ?? 0;
    this.name = p?.name ?? "";
    this.productId = p?.productId ?? 0;
    this.trends = p?.trends ?? [];
    this.servers = p?.servers ?? [];
    this.subscriptions = p?.subscriptions ?? [];
    this.queues = p?.queues ?? [];
  }
}
