import { getState } from "../vueInstance";

export default function isDevEnvGuard(to: any, from: any, next: any) {
  const state = getState();

  if (!state.environment.isDevEnv) {
    return;
  } else {
    next();
  }
}
