








































































import Component from "vue-class-component";
import LighthouseView from "@/views/lighthouse/LighthouseView";
import Build from "@/typing/lighthouse/build.class";
import Loader from "@/components/Loader.vue";
import BuildDetail from "../lighthouse/BuildDetail.vue";
import ToastrHelper from "@/helpers/toastHelper";
import storeTypes from "@/store/storeTypes";
import { EventBus } from "@/utils/eventBus/eventBus";
import { EventBusEvent } from "@/utils/eventBus/eventBusEvent.enum";

@Component({
  components: {
    Loader,
    BuildDetail,
  },
})
export default class Builds extends LighthouseView {
  get items(): Build[] {
    let items = [...this.$store.state.builds];

    if (items.length === 0) {
      return [];
    }
    return items.filter((x) => x.isLatestBuild);
  }

  get sortOptions(): any {
    return this.fields
      .filter((f) => f.sortable)
      .map((f) => {
        return { text: f.label, value: f.key };
      });
  }

  isLoading = false;
  fields: any[] = [
    {
      key: "name",
      label: this.$t("BuildName") as string,
    },
    { key: "buildNumber", label: this.$t("BuildNumber") as string },
    {
      key: "timestampFormat",
      label: this.$t("LastBuild") as string,
      sortable: true,
    },
    { key: "branch", label: this.$t("Branch") as string, sortable: true },
  ];
  pageSize = 25;
  totalRows = 1;
  currentPage = 1;
  pageOptions: number[] = [25, 50, 100, 200, 500];
  filter: any = this.$route.query.q ?? null;
  filterOn = "";
  outlined = true;
  typeToSearch: string = this.$t("TypeToSearch") as string;
  filterLabel: string = this.$t("Filter") as string;

  async mounted(): Promise<void> {
    this.isLoading = true;
    try {
      this.isLoading = true;
      await this.$store.dispatch(storeTypes.actions.GET_BUILDS);
    } catch (e) {
      console.error(e);
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.totalRows = this.items.length;
    this.isLoading = false;

    EventBus.$emit(EventBusEvent.Navigated, {
      name: "Builds",
      clear: true,
    });
  }

  onFiltered(filteredItems: any): void {
    // Update Query Parameters in URL with seach value (for history back)
    this.setHistoryQueryParams({ q: this.filter });

    // Update navigation history
    EventBus.$emit(EventBusEvent.Navigated, {
      name: "Builds",
      path: location.pathname + location.search,
      clear: true,
    });

    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  async goToBuildDetail(item: Build) {
    this.$router.push({ path: `/builds/detail?key=${item.key}` });
  }
}
