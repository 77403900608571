import Version from "./version.class";

export default class PackageDetail {
  get lastVersion(): Version | null {
    return this.versions[this.versions.length - 1];
  }

  key: string;
  name: string;
  versions: Version[];
  version: string;
  isLatestVersion: boolean;
  approved: boolean;
  approvedBy: string;
  riskScore: number;

  constructor(p?: PackageDetail) {
    this.key = p?.key ?? "";
    this.name = p?.name ?? "";

    this.versions = p?.versions ?? [];
    this.version = p?.version ?? "";
    this.isLatestVersion = p?.isLatestVersion ?? false;
    this.approved = p?.approved ?? false;
    this.approvedBy = p?.approvedBy ?? "";
    this.riskScore = p?.riskScore ?? 0;

    if (p?.versions) {
      this.versions = p.versions.map((x) => new Version(x, this.name));
    }
  }
}
